.habitatdb-container{
    background-color: #f5f6fa;
    height: 100vh;
    display: flex;

    .feature-info-habitatdb,
    .feature-management-habitatdb{
        width: 45%;
        border: 1px solid #E9ECEF;
        background-color: white;
        height: 90vh;
        padding: 2em 0 0 1.5em;
    }

    .feature-info-habitatdb{
        margin: 0 2em 0 0;
    }

    .feature-management-habitatdb{
        width: 50%;
    }

    .title-feature-habitatdb{
        display: flex;
        align-items: center;

        .inactive-feature{
            color: #c7c7c7;
        }

        .active-feature{
            border-bottom: 3px solid #ff875c;
        }

        p{
            margin: 0 2em 0 0;
            font-size: 1.1em;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .dynamo-management-title{
        margin: 2em 0 0 0;
        font-weight: bold;
        font-size: 1.08em
    }

    .dynamo-management-sub{
        font-weight: bold;
        margin: 0 0 0.5em 0em;
    }


    .form-container-dynamo{
        margin: 1em 0 0 0;
    }

    .new-dynamo-input{
        display: flex;
        justify-content: space-between;

        input{
            width: 70%;
        }

        button{
            font-weight: bold;
            height: 2.5em;
            font-size: 0.9em
        }
    }

    .button-add-fields{
        button{
            font-weight: bold;
            height: 2.5em;
            font-size: 0.9em 
        }
    }

    .new-function-btn-setup{
        width: 95%;
        display: flex;
        justify-content: flex-end;
        margin: 1em 0 0 0;
        padding: 0 0 1em 0;
        border-bottom: 1px solid #E9ECEF;

        p{
            margin: 0;
        }
    }

    .attr-container{
        display: flex;

        div{
            width: 35%;
            margin: 0 1em 0 0;
        }
    }

    .display-none{
        display:none
    }
    
    .container-dynamos-box{
        margin: 2em 0 0 0;

        .funcs-setup-box{
            width: 95%;
            
            .box-feature-item{
                width: 50%;
                justify-content: flex-end;
                align-items: flex-end;
            }
            
            button{
                width: 7em;
            }
        }
    }
}

.aurora-db-management{
    .new-aurora-input{
        display: flex;
        align-items: center;
        margin: 1em 0 0 0;

        input{
            width: 92%;
        }
        
        button{
            font-size: 0.9em;
            font-weight: bold;
        }
    }

    .new-aurora-input-2{
        display: flex;
        align-items: center;
        margin: 1em 0 0 0;

        input{
            width: 45%;
            margin: 0 1em 0 0;
        }

        select{
            width: 45%;
        }
    }

    .aurora-inputs{
        button{
            font-size: 0.9em;
            font-weight: bold;
            margin: 0.9em 0 0 0;
        }
    }

    .spinner-container{
        margin: 1em 0 0 0;
    }
}

.error-display{
    margin: 1em 0 0 0;
    font-weight: bold;
    font-size: 0.9em;
}