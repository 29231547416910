.git-clone-modal{
    .form-group{
        width: 95%;
    }

    .form-check{
        display: flex;
        

        .form-check-input{
            margin-top: .3rem !important;
            margin-left: -1.25rem !important;
        }
    }
}

.modal-content{
    margin-top: 0 !important;
}

.functions-list-container{
    margin: -2.2em 0 0 1em;
}

.tree-control_wrapper {
    max-width: 500px;
    margin: auto;
    border-radius: 3px;
}

.e-list-parent{
    display:flex;
    flex-direction: column;
}

.e-text-content{
    width: 100%;
    text-align: left;
}

.func_details {
  display: block;
  margin-bottom: 1em;  
}

.func-form-label {
  padding-top: 0.5em;
}