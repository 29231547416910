.account-ctn {
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
	padding-right: 4.5em;
}

.account-ctn-right {
  background-color: #FBFBFD;
}

.userSetting-account{
  margin-top: 3rem;
}

@media (max-width: 1024px) { 
  .settingsContainer {
    margin: 0;
    padding: 0;
  }
  
}
