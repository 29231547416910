.habitat-funcs-management-container{
    width: 95%;

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
}

.box-feature-item{
    text-align: center;
    display: flex;
    align-items: center;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.5em 0 0;

    p{
        margin:0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span{
            margin: 0 0 0 -1em;
        }
    }
}

.funcs-habitat-spinner{
    margin: 0.5em 0 0.5em 0;
}

.title-setup-management-funcs{
    display: flex;
    align-items: center;
    padding: 0 0 1em 0;

    h5{
        margin: 0;
        font-weight: bold;
    }
}

.funcs-setup-box{
    display: flex; 
    border: 1px solid #E9ECEF;
    border-radius: 5px;
    padding: 0.8em;
    font-size: 0.92em;
    margin: 1em 0 1em 0;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
}

.box-feature-name-funcs{
    display: flex;
    font-weight: bold;
    align-items: center;
    width: 50%;

    p{
        margin: 0;
    }

}

.text-feature-items{
    width: 55%;
    display: flex;
}

.title-setup-w-new{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E9ECEF;
}

.new-function-btn-setup{
    color: #1d7aff;
    cursor: pointer;
    font-weight: bold;
}

.new-function-container{
    padding: 1em 0 1em 0;
    border-bottom: 1px solid #E9ECEF;

    p{
        font-size: 1.1em;
        font-weight: bold;    
    }
    
    display: flex;
    flex-direction: column;
}

.setup-container-funcs{
    border-bottom: 1px solid #E9ECEF;
}

.box-feature-item{
    button{
        width: 100%;
    }
}

.new-function-setup{
    display: none;
}

.new-function-show{
    display: flex;
}