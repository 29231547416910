.help-main-content{
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
	padding-right: 4.5em;
}

.leftSettingsCol {    
  background-color: #FFFFFF;
}

.onboardingTour-container{
  border: 0.125rem solid #d5d5d6;
  width: 95%;
  height: 12rem;
  margin: 4.25rem auto 0 auto;
  border-radius: 0.3rem;
}

.help-onboarding-icon{
  background-color: #0d3671;
  color:#F5F6FA;
  width: 2rem;
  height: 2rem;
  display:flex;
  align-items:center;
  justify-content: center;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  margin-top: 2.3rem;
  float: left;
}

.help-onboarding-title{
  width: 10rem;
  height: 3rem;
  font-size: 1rem;
  font-weight:bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: 4rem;
  color: #81889d;
}

.tour-title{
  color: #525b76;
  margin-bottom: 0;
}

.help-onboarding-date{
  font-size: 0.875rem;
  font-weight: 600;
  color: #81889d;
}

.onboarding-under-line{
  border-bottom: 0.125rem solid #d5d5d6;
  width: 18rem;
  margin-top: 0;
  margin-left: -3.5rem;
  margin-bottom: 1rem;
}

.help-onboarding-description{
  font-size: 0.875rem;
  font-weight: 600;
  color: #81889d;
  margin-top: 2rem;
  margin-left: 0.5rem;
}

.onboarding-btn{
  background-color: #1D7AFF;
  border-color: #1D7AFF;
  font-weight: bold;
  font-size: 0.8125rem;
  float: right;
  margin-top: -4.375rem;
}

.rightSettingsCol {    
  background-color: #fbfbfd;
}

.additional-title{
  font-weight: bold;
  margin-top: 4rem;
  margin-left: 3.5rem;
  margin-bottom: 2rem;
  font-size: 1.125rem;
}

.documentation-tittle,
 .support-title{
  font-weight: 600;
  margin-left: 3.5rem;
  margin-bottom: 1rem;
}

.under-line{
  border-bottom: 0.125rem solid #d5d5d6;
  width: 20rem;
  margin-top: 0;
  margin-left: 3.5rem;
  margin-bottom: 1rem;
}

.help-description{
  font-size: 0.875rem;
  color: #81889d;
  font-weight: 600;
  margin-left: 3.5rem;
  margin-bottom: 2rem;
}
