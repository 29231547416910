* {
  margin: 0;
  padding: 0;
}

#mobile-tablet-main{
  width: 100%;
  height: 100%;
  background-color: #F5F6FA;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
  
.mobile-tablet-container{
  font-family: BasisGrotesquePro, Arial, sans-serif;
  text-align: center;
  background-color: #ffff;
  box-shadow:0 0 0.4rem;
  width: 80%;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.log-out-button{
  color: #2f7bf7;
  font-weight: bold;
  font-size: 0.9em;
  margin: 1em 0 0 0;
  text-decoration: underline;
  cursor: pointer;
}

.mobile-tablet-message{
  text-align: center;
  margin-top: 3rem;
  -webkit-box-sizing: border-box;  /*webkit*/
  -moz-box-sizing: border-box;  /*Firefox*/
  box-sizing: border-box;
}

#suggetion-btn{
  font-weight: bold;
  width: 10rem;
  display: inline-block;
  margin: 0 auto; 
  position: relative;
  font-size: 0.9em;
}


#suggetion-btn:hover{
  border: none;
  background-color:  #005b8f;
}

.telescope{
  width: 11vw;
  position: absolute;
  margin-top: 2vh;
  margin-left: 5vw;
}

@media screen and (max-width: 480px) { 
  .mobile-tablet-container{
    width: 90vw;
    height: auto;
    font-size: 0.9em;
    padding: 1em;
     }
  .mobile-tablet-message{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

