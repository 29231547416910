@import '../variables/app_layout_vars.scss';

@media only screen and (max-width: 998px) and (min-width: 400px) {

  .log-container-right {
    background-image: url('../../images/Rectangle.png');
    background-repeat: no-repeat;
    background-position: 90% 0px;
    background-size: cover;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 999px) {

  .log-container-right {
    background-image: url('../../images/Rectangle.png');
    background-repeat: no-repeat;
    background-position: 90% 0px;
    background-size: cover;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1201px) {

  .log-container-right {
    background-image: url('../../images/Rectangle@2x.png');
    background-repeat: no-repeat;
    background-position: 99% 0px;
    background-size: cover;
  }
}

@media only screen and (min-width: 1921px) {

  .log-container-right {
    background-image: url('../../images/Rectangle@2x.png');
    background-repeat: no-repeat;
    background-position: 99% 0px;
    background-size: cover;
  }
}

.log-big-box {
  width: 100%;
  height: 100%;
  /*text-align: center;*/
}


.log-container-left {
  width: 50%;
  height: 100vh;
  float: left;
  text-align: left;
  /*padding-left: 12.5%;*/
}

.log-container-right {
  width: 50%;
  height: 100vh;
  float: left;
}

.logo {
  width: 25em;
  margin: 0 auto;
  padding-top: 12.5%;
  padding-left: 0em;
}

.kor-size {
  min-width: 3.4em;
}

.sign-title {
  width: 25em;
  margin: 2em auto 1em auto;
  display: flex;
  justify-content: space-between;

  p{
    margin: 0;
  }
}

.sigtext {
  padding-top: 0;
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $black;

  p{
    margin: 0;
  }
}

.sign-form {
  width: 25em;
  margin: 0 auto;

  .login{
    align-items: flex-start;
  }
}

.create-account{
  width: 90%;
  font-size: 0.8em;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  margin: 0.5em 0 0 0;

  p{
    margin: 0;
  }

  p:nth-child(1){
    width: auto;
    margin: 0 0 0 40%;
    text-align: right;
  }

  p:nth-child(2){
    width: auto;
    text-align: right;
  }
}

.create-account-sign-up{
  width: 90%;
  font-size: 0.8em;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  margin: 0.5em 0 0 0;

  p{
    margin: 0;
  }

  p:nth-child(1){
    width: auto;
    margin: 0 0 0 47%;
    text-align: right;
  }

  p:nth-child(2){
    width: auto;
    text-align: right;
  } 
}


.button-signup-page{
  margin: 0em 0 1em 0;
  display: flex;
  justify-content: flex-end;

}

.sign-instructions {
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 0.8em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $black;
  width: 100%;
}

.tto {
  width: 21.2em;
}

.spacing-top {
  padding-top: 0%;
}

.sign-input {
  border-radius: 4px;
  border: solid 1px $gray-300;
  background-color: $white;
  min-width: 100%;
  padding: 12px 20px;
  margin-top: 1em;
}

.more-spacing {
  padding-top: 2%;
}

.remember-spacing {
  padding-top: 2%;
}

.button-signin {
  width: 10em;
  height: 2.5em;
  border-radius: 4px;
  background-color: $button-signin;
  color: $white;
  padding: 0.5em 0.76em;
  border: none;
  margin-left: 0em;
  font-size: 0.9em;
  font-weight: normal;
}

.button-signin-google {
  width: 10em;
  height: 2.5em;
  border-radius: 4px;
  background-color: #E94234;
  color: $white;
  padding: 0.5em 0.76em;
  border: none;
  margin-left: 0em;
  font-size: 0.9em;
  font-weight: normal;
}

.button-signin-github {
  width: 10em;
  height: 2.5em;
  border-radius: 4px;
  background-color: #252A2E;
  color: $white;
  padding: 0.5em 0.76em;
  border: none;
  margin-left: 0em;
  font-size: 0.9em;
  font-weight: normal;
}

.button-signup {
  width: 9.5em;
  height: 2.5em;
  border-radius: 4px;
  background-color: $button-signin;
  color: $white;
  padding: 0.5em 0.76em;
  border: none;
  margin-left: 0em;
}

.txtp {
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 0.8em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: normal;
  /*text-align: center;*/
  padding-left: 7em;
  padding-top: 2em;
  color: $black;
  margin: 0;
}

.spacing-top{
  width: 90%;
}
.custom-control{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 1em 0;
  padding: 0;
}

.linked-to {
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 0.9em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: normal;
  text-align: center;
  margin-left: 0em;
  color: $primary-blue;
}

.forgot-pass{
  display: none;
}

.nothing{
  width: 90%;
}

@media only screen and (max-width: 600px)  {
  .log-big-box{
    overflow: hidden;
  }

  .log-container-left{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;


    .logo{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .sign-title{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

    }

    .sign-instructions{
      text-align:center;
    }

    .sign-form{
      width: 90%;
    }

    .tto{
      width: 100%;
    }

    .nothing{
      width: 100%;
    }

    .sign-input{
      min-width: 100%;
      margin: 1em 0 0 0;
    }

    .button-signin{
      margin: 1em 0 0 0;
    }

    .custom-control{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0;
      font-size: 0.9em;
      margin: 0.5em 0 0 0;
    }

    .remember-label{
      margin: 0.5em 0 0 0em;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .create-account-cont{
      margin: 1em 0 0 0;
    }

    .create-account-sign-up{
      justify-content: center;

      p:nth-child(1){
        width: auto;
        text-align: center;
        margin: 0 0.5em 0 0;
      }
    
      p:nth-child(2){
        width: auto;
        text-align: center;
      }
    }

    .create-account{
      justify-content: center;

      p:nth-child(1){
        width: auto;
        text-align: center;
        margin: 0 0.5em 0 0;
      }
    
      p:nth-child(2){
        width: auto;
        text-align: center;
      }
    }

    .button-signup-page{
      margin: 1em 0 0.5em 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    
      .button-signup{
        margin: 0;
      }

    }

  }

  .log-big-box{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .log-container-right{
    width: 100%;
    height: 20vh;
    background-image: url('../../images/Rectangle.png');
    background-repeat: no-repeat;
    background-position: 90% 0px;
    background-size: cover;
  }

  .spacing-top{
    width: 100%;
  }



}

@media only screen and (max-width: 998px) and (min-width: 400px){
  .log-big-box{
    overflow: hidden;
  }

  .log-container-left{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;


    .logo{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .sign-title{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

    }

    .sign-instructions{
      text-align:center;
    }

    .sign-form{
      width: 90%;
    }

    .tto{
      width: 100%;
    }

    .nothing{
      width: 100%;
    }

    .sign-input{
      min-width: 100%;
      margin: 1em 0 0 0;
    }

    .button-signin{
      margin: 1em 0 0 0;
    }

    .custom-control{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0;
      font-size: 0.9em;
      margin: 0.5em 0 0 0;
    }

    .remember-label{
      margin: 0.5em 0 0 0em;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .create-account-cont{
      margin: 1em 0 0 0;
    }

    .create-account-sign-up{
      justify-content: center;

      p:nth-child(1){
        width: auto;
        text-align: center;
        margin: 0 0.5em 0 0;
      }
    
      p:nth-child(2){
        width: auto;
        text-align: center;
      }
    }

    .create-account{
      justify-content: center;

      p:nth-child(1){
        width: auto;
        text-align: center;
        margin: 0 0.5em 0 0;
      }
    
      p:nth-child(2){
        width: auto;
        text-align: center;
      }
    }

    .button-signup-page{
      margin: 1em 0 0.5em 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    
      .button-signup{
        margin: 0;
      }

    }

  }

  .log-big-box{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .log-container-right{
    width: 100%;
    height: 20vh;
    background-image: url('../../images/Rectangle.png');
    background-repeat: no-repeat;
    background-position: 90% 0px;
    background-size: cover;
  }

  .spacing-top{
    width: 100%;
  }



}