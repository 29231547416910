.request-errors-container{
    padding:0em 0em 0 0;
    width: 100%;
    height:50%;

    .graphic-container{
        background-color: white;
        width: 100%;
        height: 16em;
        padding: 0em;

        .graphic{
            background-color:#F5F6FA;
            width: 100%;
            height: 100%;
            
        }
    }
}

.request-error-blank{
    background-color: white;
    width: 100%;
    height: 16em;
    border: 1px solid #E9ECEF;
    padding: 0.5em;
    
    div{
        background-color:#F5F6FA;
        width: 100%;
        height: 100%;
    }
}

.animate_animated,
.animate__rotateIn{
    --animate-duration: 300ms !important;
  }