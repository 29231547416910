// SUBTITLES

.messages-page-subtitle {
    margin: 2.5em 0 1em 2em !important;
    text-transform: uppercase;
    font-family: BasisGrotesquePro;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.20;
    letter-spacing: 1px;
    color: #212529;
}

.invitations-page-subtitle {
    margin-top: 2.5em !important;
    margin-left: 2em;
    margin-right: auto !important;
    margin-bottom: 2em;
    text-transform: uppercase;
    font-family: BasisGrotesquePro;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.20;
    letter-spacing: 1px;
    color: #212529;
}


//MESSAGES & PAGEBOX

.messages {
    margin-left: 2em;
    margin-top: 3em;
}
.invitations {
    margin-top: 3em;
    margin-left: 2em;
}

// Invites
.invite-page-box {
    margin-left: 0.5em;
}

.invite-name {
	padding: 0.15em;
}

.invite-length-name{
    display: block;
    width: 8em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.accept-link {
    text-align: right;
}
.ic-rej {
    font-size: 1.2em;
    padding-top: 0.15em;
    color: #ff685c;
}

.ic-rej:hover {
    color: #bd4b42;
}

.reject-link {
    text-align: right;
}
.ic-acc {    
    font-size: 1.2em;
    padding-top: 0.15em;
    color: #6ef4bb;
}

.ic-acc:hover {
    color: #56c495
}

.loding-invitations {
    margin-top: -3.5em;
    margin-right: 28em;
}
.loding-messages {
    margin-top: -5.8em;
    margin-left: -98em;
}

.habitats-functions-container,
.errors-notifications-container{
    height: 95%;
    width: 50%;
}


.graphs-overview{
    margin: 3em 2em 0em 0;
    border: 1px solid #F0F1F9;
    background-color: #F5F6FA;

    .table-graph::-webkit-scrollbar{
        display: none;
      }

    .table-graph{
        background-color: white;
        padding: 1em 0 1em 0;
        display: flex;
        justify-content: center;
        height: 15em;
        overflow-y: scroll;



        table{
            width: 98%;
            

            th, td{
                text-align: center;
                padding: 0.3em;
            }

            th{
                color: #4C589E;
                text-transform: uppercase;
                font-size: 0.7em;
                font-weight: bold;
            }

            td{
                color: #8186A5;
            }
        }
    }

    .first-col{
        text-align: left !important;
        color: #2A3158 !important;
        font-weight: bold;
    }
}

.overview-container{
  width: 100%;
  height:100vh;
  margin: 0em 0 0 0em;
  display: flex;
  padding: 2em;

  .overview-repostories-section{
      width: 30%;
  }

  .reload-icon{
    background-color: #F0F1F9;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

  .arrow-image-container{
      display: flex;

  }

  .title-subsection-overview{
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-between;

    p{
        margin: 0 1em 0 0;
        font-weight: bolder;
        font-size: 1.3em;
        letter-spacing: 1px;
    }

    .arrow-image{
        img{
            width: 1.5em;
            height: 1.5em;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EA6F2E;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        cursor: pointer;
    }
  }
  .overview-data{
    width: 70%;
    border-right: 2px solid #F0F1F9;
    margin: 0 2em 0 0;
    
    .overview-data-section{
        display: flex;
        margin: 3em 2em 0 0;
        justify-content: space-between;
        
        .metric-data-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p{
                margin: 0;
            }

            div:nth-child(1){
                background-color: #F0F1F9;
                width: 140px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 60px;
                font-weight: bold;
            }

            p:nth-child(2){
                font-weight: bold;
                font-size: 0.7em;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin: 1em 0 0 0;
            }
        }

    }
  }

}

.habitats-functions-container {
    background-color:white;
    border-right: 1.1px solid #E9ECEF;
    padding: 0 0 0 2em;
}

.errors-notifications-container{
    background-color: #FBFBFD;
}

.habitats-box-container{
    width: 95%;
    height: 85%;
    overflow-y: scroll;
}

.alerts-box-container,
.funcs-box-container{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.funcs-box-container::-webkit-scrollbar,
.alerts-box-container::-webkit-scrollbar,
.habitats-box-container::-webkit-scrollbar{
    display: none;
}

.blank-section{
    color: #c7cfd1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blank-section-notifications{
    color: #c7cfd1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #E9ECEF;
}

.blank-section-funcs{
    color: #c7cfd1;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.blank-section-repos{
    color: #2A3158;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;  
    font-size: 1.3em;
    font-weight: bold;
    text-transform: uppercase;

    button{
        text-transform: uppercase;
        font-size: 0.7em;
        width: 15em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        background-color: #EA6F2E;
        border-color: #EA6F2E;

        a{
            color: white;
            text-decoration: none;
        }
    }

    button:hover{
        background-color: #EA6F2E;
        border-color: #EA6F2E;    
    }
}

.title-section-ui{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2em 0 0;
    
    h2{
        font-weight: bold;
        padding-left: 15px;
    }


    .calendar-create{
        display: flex;
        align-items: center;

        p{
            margin: 0 0.5em 0 0;
            font-size: 0.9em;
            color: #909AFF;
        }

        button{
            background-color: #EA6F2E;
            border-color: #EA6F2E;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            width: 160px;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                margin: 0 0.5em 0 0;
            }
        }

        .form-control{
            width: 160px;
            border: 1px solid #4C589E;
            margin: 0 1em 0 0;
        }
    }


}