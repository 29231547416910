.toast-container{
    position: fixed;
    top: 2;
    right: 0;
    width: 13em;
    margin: 1em 1em 0 0;
    display: none;

    .toast-header{
        padding: 0.25em;
    }
    

}

.message-toast{
    padding: 0.5em;
    margin: 0;
}