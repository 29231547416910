.billing-main-content{
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
	padding-right: 4.5em;
}

.billing-row {
	padding: 0 0 0 1em;
	display: inline-block;
	/*padding-left: 3em;*/
  }
  
  .billing-history-row {
	  margin: 1em 0 0 0em;
  }
  
  .billing-page-box {
	  width: 55em;
  }
  
  .page-box.billing-plan-page-box {
	  padding-right: 2.5em;
	  padding-left: 0.5em;
	  margin-right: 3em;
	  min-width: 260px;
	  min-height: 330px;
  }
  
  .billing-column-left {
	  min-width: 5em;
	  padding-right: 3em;
  }
  
  .billing-column-text-normal {
	  opacity: 0.8;
	  font-family: BasisGrotesquePro;
	  font-size: 14px;
	  font-weight: normal;
	  font-style: normal;
	  font-stretch: normal;
	  line-height: 1.71;
	  letter-spacing: normal;
	  color: var(--black);
  }
  
  .billing-column-text-info {
	  font-family: BasisGrotesquePro;
	  font-size: 14px;
	  font-weight: 500;
	  font-style: normal;
	  font-stretch: normal;
	  line-height: 1.71;
	  letter-spacing: normal;
	  color: var(--black);
  }
  
  .billing-column-empty {
	  min-width: 13em;
  }
  .billing-column-right {
	  min-width: 5em;
  }
  .billing-column-last {
	  min-width: 5em;
	  align-items: center;
  }
  
  .plan1 {
	  margin-left: 0em;
  }
  
  .page-title-cc {
	  margin-top: 1em !important;
	  margin-left: 2em;
  }
  
  .StripeCheckout {
	margin-left: 2em;
	overflow: hidden;
	display: inline-block;
	background: linear-gradient(rgb(40, 160, 229), rgb(1, 94, 148));
	border: 0px;
	padding: 1px;
	text-decoration: none;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
	cursor: pointer;
	visibility: visible;
	user-select: none;
  }
  
  .StripeCheckout {
	  background-image: linear-gradient(rgb(125, 197, 238), rgb(0, 140, 221) 85%, rgb(48, 162, 228));
	  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	  font-size: 14px;
	  position: relative;
	  padding: 0px 12px;
	  display: block;
	  height: 30px;
	  line-height: 30px;
	  color: rgb(255, 255, 255);
	  font-weight: bold;
	  box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
	  text-shadow: rgba(0, 0, 0, 0.25) 0px -1px 0px;
	  border-radius: 4px;
  }
  
  .plan-card {
	  margin-left: 2em !important;
	  position: relative;
	  display: -ms-flexbox;
	  display: flex;
	  -ms-flex-direction: column;
	  flex-direction: column;
	  min-width: 16em;
	  word-wrap: break-word;
	  background-color: #fff;
	  background-clip: border-box;
	  border: 1px solid rgba(0,0,0,.125);
	  border-radius: .25rem;
  }
  
  .plan-card-header {
	  margin-left: auto;
	  margin-right: auto;
	  padding: .75rem 1.25rem;
	  margin-bottom: 0;
	  background-color: rgba(0,0,0,0.0);
	  border-bottom: 1px solid rgba(0,0,0,.125);
  }
  .plan-name{
	  position: relative;
  }
  
  .price-card-body{
	  margin-left: auto;
	  margin-right: auto;
	  padding: .75rem 1.25rem;
  }
  
  .plan-price{
	  margin-left: auto;
	  margin-right: auto;
  }
  .modal-content {
	margin-top: 55%;
  }

.pay-hist-rej.fa-times-circle {
	color: #ff685c;
	margin-right: 0.5em;
}

.pay-hist-acc.fa-check-circle {    
	color: #6ef4bb;
	margin-right: 0.5em;
}
.date-width{
	min-width: 5em;
}

.billing-page-box{
	padding: 1em 0 0 3.5em;
}

.border-bottom-line{
	border-bottom: 0.05em solid rgba(112, 112, 112, 0.125);
	border-bottom-style: solid;
	width: 88%;
	margin-left: 0.3em;
}

.receipt-link{
	font-weight: bold;
	text-decoration: none !important;
}
.fa-receipt{
	margin-left: 2em;
	margin-right: 1em;
}
.receipt-hist-text{
	color: black;
	text-decoration: none;
}

.StripeCheckout {
	background-color: #007bff !important;
}

.current-plan {
	color: rgb(187, 187, 187); 
	text-align: center;
	padding-top: 0.5em;
}

.error {
	padding: 1em;
	padding-top: 0.5em;
    color: #eb1c26;
}

.btn-pay {
	color: #fff;
    background-color: #45a24d;
    border-color: #37754c;
}

.mdDowngrade {
	background-color: white;
}

.mdDowngradeTitle {
	color: black;
}

.payment-subscription-container{
	display: flex;
	width: 100%;
}
