//Responsive Variables
$screen-sm-min: 300px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

//Responsive Breakpoints
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

nav {
  width: 80%;
  margin: 10%;
  top: 0;
  background: #212121;
  color: #ccc;
  position: absolute;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  height: 100%;
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

li:hover {
  background: #848484;
  color: #212121;
}

.fixed-nav {
  position: normal;
  min-width: 100%;
  z-index: 1;
}

.content {
  width: 100%;
  margin: 0 auto;
  text-align: justify;
  padding-top: 40px;
}

.content > div {
  margin: 20px 0;
}

.flux-navbar {
  width: 100%;
  height: 10%;
  margin-bottom: 1em;
  text-decoration: none;
  list-style-type: none;
  margin: 0 0 0 0;
  border-bottom: 0.02px solid #e4e4e4;
  margin-top:0;
  background-color: white;
}

.flux{
  font-weight: bold;
  text-decoration: none;
  list-style-type: none;
  font-size: 25px;
  margin: 0 0 0 0;
  padding: 3px 300px ;
  margin-top:0;
  position: absolute;
  
}

/*Lateral Bar*/


//iconos
.iconos{
  margin-left: 0.2em;
  margin-top: -2.5rem !important;
  margin-right: 0.5em;
  color:#1D7AFF;
  background-color: white;
  padding: 10px 0 8px 8px;
  width: 30px;
  height: 30px;
 }


.main {
  margin-left: 100px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

//title left menu
.texto-nav{
  padding: 2px;
  opacity: 0.5;
  font-family: BasisGrotesquePro;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--black);
  margin-top: 1.8em;  
}

.texto-nav-two {
  padding: 12px;
  margin-top: 2em;
  opacity: 0.5;
  font-family: BasisGrotesquePro;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--black);  
}

.nav-side-text {
  font-family: BasisGrotesquePro;
  font-size: 12px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--black);
  margin-top: 0;
  padding: 0 !important;
 }

.txt{
  color: #000668;
  
}

//Dropdown btn color
.btn-success {
  color: #fff;
  background-color: white !important;
  border-color: white !important;
  color: black;
  width: auto;
}

#dropdown-basic:hover, #dropdown-basic:not(:disabled) {
  color: black;
  outline: none;
}

//Letter inside Dropdown-botton
.span-button-header{
  display: inline-block;
}

#dropdown-basic {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.Icon {
  color: #009eeb;
  margin-right: 10px;
}

.activeNav {
  background-color: rgba(29, 123, 255, 0.322);
  border-radius: 4px;
}

.dropdown-item:hover {
  background-color: rgba(29, 123, 255, 0.322) !important;
  border-radius: 4px !important;
}

element.style {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 42px, 0px);
}

.mt-2.dropDown.dropdown-menu.show {
  font-size: small;
}  

.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #ffff !important;
}

.success:focus {
 box-shadow: 0 0 0 0.2rem rgba(16,32,83,.5) !important;
}

.dropdown-toggle::after {
  margin-left: 1em !important;
  margin-top: 0.8em !important;
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
background-color: transparent;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #102053 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;

  background-color: white;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: black;
}

.dropbtn {
 
  color: white;
  padding: 5px;
  font-size: 9px;
  border: none;
  cursor: pointer;
   }


.flux-text{
  font-family: BasisGrotesquePro;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--white); 
  padding-left: 0.7em;
}

.line{
  border-left: 1px solid var(--light-blue);  
}
.app-logo {
 margin-left: 3em;
 margin-right: 2em;
}

.bars-icon-navbar{
  width: 1.5em;
  cursor: pointer;
}
.right {
 position: absolute;
 right: 0px;
}

.brand-logo{
  display: fixed;
  padding: 5px 15px 10px 20px;
  
}

.line{
    width: 1px;
    height: 44px;
    opacity: 0.5;
    border: 0.02px solid #e4e4e4;
    background-color: var(--light-blue);
}

.spacer{
  flex: 1;
}

.titleflux-text{
  color:black;
  display: fixed;
  padding: 5px 0px 0px 15px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.displaystatus ul{
  color: white;
  display: fixed;
  align-items: center;
}

.displaystatus{
  padding: 0px 20px;
}

.profilenamenavbar{
  color: white;
  padding: 0px 5px;
}

.caret-down{
  width: 30px;
  height: 30px;
}

.avatar{
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(31, 0, 197, 0.1);
  margin-top: 0.2em;
  margin-right: 1.5em;
  margin-left: 0.5em;
}

.Dropdown-control:nth-child(2) {
  display: none;
}

.nav-header-container{
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 1.4em 2em;
  border-bottom: 1px solid #e9ecef;
  
  .search-input-kor{
    display: flex;
    align-items: center;
    background-color: #F0F1F9;
    width: 20em;
    padding: 0 2em;
    border-radius: 5px;
    display: none;

    svg{
      color: #909AFF;
    }

    .form-control{
      margin: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;
      font-size: 0.9em;
    }
  }

  .avatar-kor-settings{
    display: flex;
    align-items: center;

    .avatar-name{
      display: flex;
      align-items: center;
    }

    .logout-img{
      cursor: pointer;
      img{
        width: 16px;

      }
    }

    .icons-settings{
      display: flex;
      align-items: center;
      margin: 0 0 0 1.5em;

      div{
        width: 2.4em;
        height: 2.4em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:  #F0F1F9;
        border: 1px solid  #F0F1F9;
        border-radius: 50%;
        margin: 0 0.5em 0 0;
      }
    }

    p{
      margin: 0;
      font-size: 0.9em;
    }
  }
  
 
  .header-kor-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color:black;
    background-color:white;

    .app-logo {
      margin: 0 0 0 0.6em;
      width: 1.8em;
    }

    svg{
      color: #909AFF;
      font-size: 1.3em;
      cursor: pointer;
    }

    .line{
      height: 30px;
      width: 2.5px;
      border:  1px solid #1D7AFF;
      background-color: #1D7AFF;
      border-radius: 50px;
      margin: 0 0 0 1.8em;
      opacity: 1;
    }
    .form-control{
      margin: 0;
      background-color: #F0F1F9;
      width: 18em;
    }
    .select-teams{
      display: flex;
      align-items: center;
      margin: 0 0 0 2em;
      p{
        margin: 0 1em 0 0;
        color:#1D7AFF;
        font-size: 0.9em;

      }
    }

  }
}

.banner-templates{
  background-color: #ff6701;
  padding: 1em 0 1.3em 3em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon-text-banner{
    display: flex;
    align-items: center;
  }

  .banner-template-icon{
    background-color: #d65b09;
    width: 2.5em;
    height: 2.5em;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .text-banner-templates{
    margin: 0 0 0 1em;

    p{
      margin: 0;
    }

    p:nth-child(1){
      font-size: 1.3em;
      font-weight: bold;
    }

    p:nth-child(2){
      font-size: 1em;
    }
  }

  .get-started-button button{
    color: white;
    border: 2px solid white;
    border-bottom: 6px solid white;
    font-weight: bolder;
    border-radius: 5px;
    margin: 0 3em 0 0;
  }

  .get-started-button button:hover{
    background-color: white;
    color: #d65b09;
  }

}

