
.modal-kor-small-right {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color:rgba(42, 49, 88, 0.5);
  z-index:1070;
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  top: 0%;
  left:0%;
  overflow-y: scroll;
  

  .close-title{
    display: flex;
    align-items: center;

    h3{
      margin: 0;
    }

    img{
      margin: 0 1em 0 0;
      cursor: pointer;
    }
  }
  
  .box-modal-options-container-small-right::-webkit-scrollbar{
    display: none;
  }

  .box-modal-options-container-small-right {
      width: 45%;
      height: 100%;
      padding: 3em;
      background-color: white;
      border: solid 1px #e9ecef;
      overflow-y: scroll;

      p:nth-child(1) {
          font-weight:300;
          text-align: left;
          margin: 0 0 0.5em 0;
      }

      p:nth-child(2),
      p:nth-child(3) {
          text-align: left;
          font-weight: 300;
      }

      h3 {
        font-weight: bolder;
      }

      .input-label {
        color: #4C589E;
        font-weight: 300 !important;
        font-size: 14px;
      }

      .close-icon {
        color: #909AFF;
        margin-right: 20px;
        cursor: pointer;
      }

      .delete-icon {
          color: #ff685c
      }

      .delete-icon-safe{
          color: #14ca88
      }
  }

  .buttons-modal-kor {
      display: flex;

      button{
          margin: 1em 0.5em 0 0;
      }
  }

  .input-label{
    margin: 0 !important;
  }
}

.modal-kor-small-right::-webkit-scrollbar{
  display: none;
}

.return-button-modal{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 5em 0 0 0;

  button{
    width: 10em;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
    height: 2.8em;
  }
}
.resources-container{
  display: flex;
  width: 100%;

  .resource-box{
    border: 1px solid #F0F1F9;
    padding: 2em 0.5em 2em 0.5em;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12em;
    margin: 0 1em 0 0;
    border-radius: 5px;

    button{
      background-color: #EA6F2E;
      border-color: #EA6F2E;
      text-transform: uppercase;
      font-size: 0.9em;
      letter-spacing: 1px;
      width: 90%;
      margin: 1em 0 0 0;
      font-weight: bold;

      a{
        color: white
      }

      a:hover{
        text-decoration: none;
      }
    }

    .info-resource{
      p:nth-child(1){
        font-size: 1.3em;
        font-weight: bold;
        text-align: center;
        margin: 0.2em 0 0 0;
      }

      p:nth-child(2){
        font-size: 0.9em;
        text-align: center;
        margin: 2em 0 0 0;
      }
    }

    .icon-resource{
      width: 3em;
      height: 3em;
      background-color: #1D7AFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      img{
        width: 2em;
        height: 2em;
      }
    }

    .folder-icon-box{
      img{
        width: 3em;
        height: 3em;
      }
    }

    .folder-icon-box-1{
      img{
        width: 2em;
        height: 2em;
      }
    }

    .folder-icon-box-2{
      img{
        width: 1.8em;
        height: 1.8em;
      }
    }

    .icon-resource-static{
      background-color: #FAB735;
    }

    .icon-resource-webapp{
      background-color: #1FC173;
    }

    .icon-resource-strapi{
      background-color: #F0F1F9;
    }


  }
}

.creds-container-modal{
  display: flex;
  align-items: center;
  margin: 0 0 1.5em 0;
  p{
    width: 20%;
    margin: 0;
  }
  select{
    width: 50%;
    margin: 0;
    background-color: #F0F1F9;
    font-size: 0.9em;
  }
  button{
    background-color: #EA6F2E;
    border-color: #EA6F2E;
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0 0 0 1em;
  }
}

.teams-modal{
  .avatar-team{
    margin: 0 1em 0 0;

    img{
      margin: 0;
    }
  }



  .team-name-update{
    display: flex;
    margin: 2em 0 0 0;
    border: 2px solid #F0F1F9;
    padding: 1em;
    align-items: center;
    justify-content: space-between;

    .update-input-team{
      display: flex;
      width: 90%;
      align-items: center;

      p{
        margin: 0;
        width: 20%
      }
  
      input{
        width: 80%;
        margin: 0;
        background-color: #F0F1F9;
        border-radius: 4px;
        border: none;
        box-shadow: none;
      }
    }
  }

  .leave-team-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1em 0 0 0;

    button{
      background-color: #8186A5;
      border-color: #8186A5;
      width: 8em;
    }
  }
  .title-subsection-team{
    font-size: 1.3em;
    font-weight: bold !important;
  }


  .members-team-table{
    margin: 1.5em 0 0 0;
    height: 22em;
    overflow-y: scroll;


    table{
      width: 100%;
      
      thead{
        th{
          font-size: 1.04em;
          border-bottom: 2px solid #F0F1F9;
        }
      }
      tbody{
        tr{
          border-bottom: 2px solid #F0F1F9;
        }
      }
    }
  }

  .members-team-table::-webkit-scrollbar{
    display: none;
  }

  .new-member-add{
    margin: 1.3em 0 0 0;
    .title-subsection-team{
      font-size: 1.3em;
      font-weight: bold !important;
    }

    .input-new-member{
      display: flex;
      align-items: center;
      margin: 0 0 1em 0;

    p{
      width: 20%;
      color: #4C589E;
    }

    input{
      width: 60%;
      margin: 0 1em 0 0;
      background-color: #F0F1F9;
      border-radius: 4px;
      border: none;
      box-shadow: none;
    }

      
    button{
      background-color: #EA6F2E;
      border-color: #EA6F2E;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.9em;
      letter-spacing: 1px;
      padding: 0.7em 0;
      width: 10em;
      height: 3em;
    }
    }
  }

  .membership-team-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2em 0 0 0;

    

    button{
      background-color: #EA6F2E;
      border-color: #EA6F2E;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.9em;
      letter-spacing: 1px;
      padding: 0.7em 0;
      width: 10em;
    }

    .membership-text{
      p:nth-child(1){
        font-size: 1.3em;
        font-weight: bold;

        span{
          color: #EA6F2E
        }
      }

      p:nth-child(2){
        span{
          font-weight: bold;
        }

        span:nth-child(1){
          color: #1D7AFF
        }

        span:nth-child(2){
          color:  #EA6F2E;
        }
      }
    }
  }

  .teams-modal-create-membership{
    margin: 1em 0 0 0;
  }
  
  .text-modal-muted{
    margin: 1em 0 0 0;
  }

  .create-team-modal-input{
    border: none;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;

    .update-input-team{
      width: 100%;

      input{
        width: 90%;
      }
    }
  }
}

.buttons-delete-item{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1em 0 0 0;

  button{
    width: 10em;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
    height: 3em;
    letter-spacing: 1px;
  }

  button:nth-child(2){
    background-color: #8186A5;
    border: 1px solid #8186A5;
    margin: 0 0 0 1em;
  }
}

.close-title{
  span{
    color: #EA6F2E;
  }
}


.delete-modal-container{
  border: 1px solid #F0F1F9;
  margin: 2em 0 0 0;
  padding: 2em 2em;

  .error-text-delete{
    background-color: #F0F1F9;
    color: #EA6F2E;
    text-transform: uppercase;
    font-weight: 600 !important;
    letter-spacing: 1px;
    padding: 0.5em 1em;
    border-radius: 5px;
    margin: 0 0 2em 0 !important;
  }

  .verification-delete{
    margin: 1em 0 0 0;

    .muted-text-1{
      margin: 0 0 2em 0 !important;
      color: #4C589E;
    }

    .muted-text-2{
      margin: 2em 0 0 0 !important;
      color: #4C589E;
      font-weight: 300 !important;
    }

    div{
      p:nth-child(1){
        font-weight: bold;
        font-size: 1.3em;
        margin: 0 !important;
      }
    }
  }

  .team-name-update{
    justify-content: flex-start;
    border: none;
    margin: 0 0 2em 0;
    padding: 0;

    .details-item{
      margin: 0 0 0 1em;
      p{
        margin: 0;
        font-weight: bold !important;
        text-transform: uppercase;
        font-size: 0.8em;

        span{
          color: #8186A5;
        }
      }

      .name-item-delete{
        font-size: 1.1em;
      }

      .last-updated-team{
        margin: 0.5em 0 -0.5em 0;
      }
    
    
      .owner-team{
        display: flex;
        font-weight: bold;
        font-size: 0.9em;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin: 0.7em 0 0 0;
        align-items: center;
    
        p{
          font-weight: bold;
          margin: 0;
        }
    
        div{
          display: flex;
          margin: 0 0 0 1em;
          background-color: #F0F1F9;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
    
          p{
            background-color: #F0F1F9;
          }
    
          p:nth-child(1){
            width: 2em;
            height: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #1D7AFF;
            color: white;
            border-radius: 50%;
          }
    
          p:nth-child(2){
            padding: 0 1em;
            border-radius: 20px;
          }
        }
      }
    
    }
  }
}
