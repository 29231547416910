.habitat-costs-container{
    background-color: #f5f6fa;
    width: 100%;
    height: 100vh;
    padding: 0.2em 0 0 0;

    .title-habitat-costs{
        background-color: white;
        border-top: 1px solid #E9ECEF;
        border-right: 1px solid #E9ECEF;
        width:100%;
        padding: 1em 0 0 1.5em;
        font-size: 1.1em;
        font-weight: bold;

        p{
            margin: 0;
        }
    }
}

.cost-activation-container{
    width: 97.5%;
    height: 80%;
    background-color:white;
    border: 1px solid #E9ECEF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p{
        margin: 0;
    }

    button{
        margin: 1.5em 0 4em 0;
    }

    .question-mark-documentation-habitat{
        display: flex;
        align-items: center;
        font-size: 0.9em;
        font-weight: bolder;

        svg{
            width: 0.9em;
            margin: 0 0 0 0.3em;
        }
    }

    .description-activation{
        text-align: center;

        a{
            font-size: 1em;
        }
    }

    .title-cost-activation{
        font-weight: bold;
        margin: 0em 0 2em 0;
        display: flex;
        align-items: center;
        
        .icon-costs-activation{
            color: white;
            background-color: #102053;
            width: 2em;
            height: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            font-size: 1.2em;
        }
        p{
            margin: 0 0 0 0.5em;
            font-size: 1.7em;
        }
    }
}



.cost-container{
   background-color: #f5f6fa; 
   padding: 0 0 2em 0;
}


.costs-cont{
    background-color: #f5f6fa;
    padding: 0em 0 2em 0em;
    width: 60%;
    height: auto;
    margin: 0em 2em 1em 0em;

    .spinner-container{
        background-color: white;
        margin: 0;
        padding: 0.5em 0 0 1.5em;
    }

    .dates-from-to{
        display: flex;
        align-items: center;
        width: 100%;
        background-color:  white;
        padding: 1em 0 0 1.5em;
        border-right: 1px solid #E9ECEF;


        p{
            margin: 0 0.5em 0 0.5em;
            font-size: 0.9em;
        }

        button{
            margin: 0 0 0 0.5em;
            width: 20%;
            height: 100%;
        }

        input{
            margin: 0 0.5em 0 0.5em;
            width: 20%;
        }

        select{
            margin: 0;
            width: 20%;
        }


    }

    .error-display{
       margin: 0em 0 0em 0;
       font-weight: bold;
       background-color: white;
       border-right: 1px solid #E9ECEF;
       width: 100%;
       padding: 0.5em 0 0 1.5em;
       
       p{
           font-size: 0.9em;
           margin: 0;
       }
       
    }

    .charts-container{
        padding: 0em 0 1em 1.5em;
        background-color: white;
        width: 100%;
        display: flex;
        border-right: 1px solid #E9ECEF;
        border-bottom: 1px solid #E9ECEF;



        .cont-chart-1{
            width: 100%;
            background-color: white;
            padding: 2em 0 0 0;
            position: static !important;

            .pie-chart{
                margin: 2em 0 3em 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

    }

    
}


.cost-container{
    display: flex;
    height: auto;

    .question-mark-documentation-habitat{
        display: flex;
        align-items: center;
        font-size: 0.8em;
        font-weight: bolder;
        margin: 0 0 1em 1.5em;

        a{
            margin: 0;
        }

        p{
            margin: 0;
        }

        svg{
            width: 0.8em;
            margin: 0 0 0 0.3em;
        }
    }


    .cont-chart-2{
        width: 40%;
        margin: 0em 2em 0 0;
        background-color: white;
        border-left: 1px solid #E9ECEF;
        border-right: 1px solid #E9ECEF;
        border-bottom: 1px solid #E9ECEF;
        display: flex;
        flex-direction: column;
        height: 100%;

        table{
            width: 95%;
            font-size: 0.9em;
            margin: 3.3em 0 1em 1.1em;
            
            thead tr{
                border-bottom: none;
            }

            td,th{
                padding: 0
            }

            thead tr{
                border-bottom: 1px solid;
                color: white;
                background-color:  #909aff;
            }

            thead th{
                padding: 0.3em 0.5em;
            }

            td{
                padding: 0.5em 0 0 0.5em;
            }
        }
    }

    .total-costs{
        border-top: 1px solid #E9ECEF;
        padding: 0.5em 0 0 0;
        width: 95%;
        font-size: 1.1em;
        margin: 0 0 1em 1.1em;
    }
}
