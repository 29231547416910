.info-secction {
  background-color: #FF6602;
  color: #ffffff;
  padding: 1.2em;
}

.check-no-domain-container{
  .check-enable-no-domain{
    margin: 1em 0 1em 0;
  }
}

.new-template-container{
  display: flex;
  width: 100%;
  height: auto;

  .info-secction{
    width: 40%;
  }

  .steps-template-container{
    width: 60%;
    background-color: #f5f6fa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0em 2em 0;
  }

  .subtitle-ctn{
    p{
      font-size: 1.2em;
    }
  }
}

.card-steps-container{
  border: 1px solid #E9ECEF;
  margin: 1.2em;
  height: 95%;
}

.width-flex{
  width: 25%;
}

.new-template-steps{
  width: 90%;
  background-color: white;
  height: 100%;
}

.info-ctn {
  border: 2px solid white;
  border-radius: 5px;
  height: 100%;
}

.img-ctn {
  width: 50px;
}

.template-title {
  border-bottom: 2px solid white;
}

.btn-template-outline {
  background-color: transparent;
  border: 1px solid #FF6602;
  color: #FF6602;
}

.btn-template-outline:hover {
  background-color: #FF6602;
  border: 1px solid white;
  color: white;
}

.btn-template {
  background-color: #FF6602;
  border: 1px solid white;
  color: white;
  margin: -2.4em 0 2em 0;
}

.btn-template:hover {
  background-color: #af4802;
}

.cred-link {
  color: #FF6602;
}

.cred-link:hover {
  color: #af4802;
}

.table-head-orange {
  background-color: #FF6602;
  color: white;
}

.deploy-link {
  font-size: 2rem;
  color: #FF6602;
}

.link-to-templates{
  color: #FF6602;
  margin: 2em 0 0 0;
}

.col-template-text{
  span{
    width: 100%;
  }

  text-align: center;
}

.deploy-link:hover {
  color: #9e4911;
}

// loader
// hay frand, play with these vars.
$loader-style: double; 
$loader-width: 0.5em;
$loader-color: #FF6602;
$loader-size: 150px;
$loader-speed: 1s;

// colors by http://flatuicolors.com/


.loader {
  width: 1em;
  height: 1em;
  font-size: $loader-size;
  position: relative;
  @media (max-width: $loader-size * 1.5), (max-height: $loader-size * 1.5) {
    font-size: $loader-size/2;
  }
  &:before, &:after {
    content: "";
    top: 0;
    display: block;
    width: 1em;
    height: 1em;
    position: absolute;
    border-width: $loader-width;
    border-style: $loader-style;
    border-color: transparent;
    box-sizing: border-box;
    border-radius: 1em;
    animation: spin $loader-speed infinite;
  }
  &:after {
    left: 0;
    border-left-color: $loader-color;
  }
  &:before {
    right: 0;
    border-right-color: $loader-color;
    animation-delay: $loader-speed/-4;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
}

.container-create-form{
  width: 100%;
  margin: 3em;

  .inputs-new-aws-credential{
    width: 90%;
  }

  .button-container-aws{
    align-items: center;
    justify-content: flex-start;
    width: 85%;
  }

  .button-container-next{
    align-items: center;
    justify-content: flex-start;
    width: 85%;  
  }
}

.number-ctn,
.number-ctn-active{
  cursor: pointer
}
