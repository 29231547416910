.credentials-main-content{        
  width: 100%;
  height: 100%;
  margin: 0;
  padding-right: 0;
  display: flex;

  .leftSettingsCol {    
    background-color: #FFFFFF;
    width: 45%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer-settings {    
      width: 80%;
      bottom: 0;
      margin-bottom: 0;
      left: 10%;
      position: relative !important;
      margin: 0em 0 1em 0;
    }
  }
  
  .rightSettingsCol {    
    background-color: #fbfbfd;
    width: 55%;
    padding: 0 2em 0 2em;
  }
}



.card-header{
  background-color: white;
  padding: 1.5em;

}

.new-git-creds{
  color: #0069d9 !important;
  font-weight: bold;
  button{
    font-size: 0.9em;
  }
}

.new-cred-btn{
  display: flex;
  justify-content: flex-end;
}


.container-git-others{
  input{
    font-size: 0.9em;
  }
  .buttons-container{
    width: 100%;
    button{
      width: 48%;
      font-weight: bold;
    }
  }
}

.buttons-container-modal-git{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button{
    width: 50%;
    margin: 0 0.5em 0 0 !important;
  }
}

.container-git-modal{
  .modal-instructions-1{
    margin: 0 0 1em 0 !important;
  }

  .cancel-button{
    margin: 3em 0 0 0;
    font-weight: bold;
  }



  .display-none{
    display: none;
  }

  .buttons-container{
    button{
      width: 3em;
      font-size: 1.20em !important;  
    }
    
  }

  .button-gits{
    width: 100%;

    p{
      text-align: left !important;
      font-weight: normal !important;
    }
  }

  .modal-instructions-2{
    margin: 0;
    font-size: 0.9em !important;
    text-align: left;
    width: 100%;
  }

  input{
    font-size: 0.9em;
    margin: 0 0 2em 0;
  }
}

.cardHeader{
  display: flex;
  align-items: center;
}

.credIcon{
  top: 10% !important;
}

.modal-title{
  width: 100%;
}

.close{
  width: 20%;
}