.modal-billing-container{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    background-color:rgba($color: #dde2ea, $alpha: 0.7);
    top:0%;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;

    .switch.btn{
        width: 7%;
        font-size:  1em;
        
    }
    
    .switch-on.btn{
        padding:0;
        font-size:  1em;
        margin: 0.5em 0 0 0;
    }
    
    .switch-off.btn{
        padding:0;
        font-size:  1em;
        margin: 0.5em 0 0 0;
    }
}

.container-plans{
    background-color:white;
    width: 85%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items:center;
}

.title-plans p{
    font-size: 1.6em;
    margin: 2em 0 1em 0;
    text-align: center;
}

.title-toggle{
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
}

.title-plans{
    width: 90%;
    display: flex;
    align-items:center;
    justify-content: center;
}

.plan-cards-container{
    width: 100%;
    height: 80%;
    padding: 0 0 1em 0;
    display: flex;
    justify-content:center;
}

.select {
    border: 3px solid #909aff !important;  
}

.sub-title {
    color: #a4abb5;
    font-size: 0.8em;
}

.plan-card-1{
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 2em;
    width: 25%;
    margin: 0 0.5em 0 0.5em;
    display:flex;
    flex-direction:column;
    height: 100%;
    position: relative;
    p{
        margin:0;
    }

    .title-description-plan{
        height: 8em;

        p{
            margin: 0;
        }
        p:nth-child(1){
            font-weight: bold;
            border-bottom: 1px solid #dee2e6;
            margin: 0em 0 1em 0;
        }
    
        p:nth-child(2){
            color: #a4abb5;
            font-size: 0.8em;
            margin: 0;
        }
    }

    .price-plan{
        height: 5em;
        p:nth-child(1){
            font-weight: bold;
            font-size: 1.4em;
            text-align: center;
        }
        p:nth-child(2){
            font-size: 0.9em;
            text-align: center;
            font-weight: bold;

        }
    }



    .plan-features{
        height: 10em;
        margin: 0 0 2em 0;
        p:nth-child(1){
            border:none;
            margin: 1em 0 0.5em 0;
            font-weight: bold;
        }

        p:nth-child(2){
            color: black;
            font-size: 1em;
            margin:0;
        }

        p:nth-child(3){
            font-weight: normal;
            text-align: left;
            font-size: 1em;
        }

        p:nth-child(2),
        p:nth-child(3),
        p:nth-child(4),
        p:nth-child(5){
            margin: 0.7em 0 0 0;
        }

    }

    .button-up-downgrade, .button-up-select {
        height: 2em;
        margin: 2em 0 0 0;
    }

}

.color-green{
    color: #6ff8bd;
    font-size: 0.7em
}

.button-up-downgrade{
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
    font-weight: bold;

    p:nth-child(1){
        border:none;
        width: 12em;
        color: white;
        background-color: #1e7aff;
        text-align: center;
        padding: 0.3em 1em;
        border-radius: 4px;
        cursor: pointer;
    }
}

.button-up-select{
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
    font-weight: bold;

    p:nth-child(1){
        border: none;
        width: 100%;
        position: absolute;
        bottom: 0;
        color: white;
        background-color: #909aff;
        text-align: center;
        padding: 0.3em 0em;
        border-radius: 0px;
    }
}

.b-close-modal{
    p{
        border:none;
        width: 12em;
        color: white;
        background-color: #1e7aff;
        text-align: center;
        padding: 0.3em 1em;
        border-radius: 4px;   
        margin: 2em 0 0 0;
        font-weight: bold;
        cursor: pointer;
    }
}

.cont-flex{
    display: flex;

    p{
        margin:0;
    }

    p:nth-child(1){
        margin: 0 3.7em 0.5em 0;
    }
}

.toggle-plan{
    font-size: 0.7em;
    display: flex;
    align-items:center;
    justify-content: flex-end;
    padding: 0.7em;
    margin: 0 0 0.5em 0;
    width: 100%;

}



.label-free{
    margin: 0.5em 1em 0 0;
    text-align: center;
}

.add-card-container{
    width: 50%;
    height: 30%;
    background-color:white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 1.2em;

    .button-up-downgrade{
        font-size: 0.8em;
    }
}

.close-modal{
    color: #0f2589;
    font-size: 1.3em;
    height: 100%;
    padding: 1em 0 0 2.4em;
    cursor: pointer;
}

.buttons-add-card{
    display:flex;

    button{
        margin: 0 0.5em 0 0
    }
}

.disabled{
    opacity: .65;
    cursor: normal;
}

.feature-billing-item{
    display: flex;
    margin: 0.7em 0 0 0;

    p{
        margin: 0 !important;
    }

    svg{
        margin: 0.7em 0.7em 0em 0;
    }
}