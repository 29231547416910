.container-step-functions{
    width: 100%;
    background-color: #f5f6fa;
    height: 100vh;
    margin: -1em 0 0 0;

    .step-function-spinner{
        margin: 0.5em 0 0 1.7em;
    }

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 2.1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
    }


    .step-functions-list{
        width: 100%;
        margin: 1em 0 0 0;
        display: flex;
        height: 90%;

        .list-step-funcs{
            width: 45%;
            margin: 0.3em 1em 0 0;
            background-color: white;
            border: 1px solid #E9ECEF;
            padding: 2em 2em 1em 0em;
            overflow-y: scroll;
        }

        .state-machine-container{
            width: 50%;
            margin: 0.3em 0 0 0;
            border: 1px solid #E9ECEF;
            background-color: white;
            padding: 1em 0em 0 0em;
        }
    }

    .title-step-funcs{
        font-size: 1.1em;
        font-weight: bold;
        margin: 0 0 0 2em;
    }
}

.box-step-func{
    border: 1px solid #E9ECEF;
    border-radius: 5px;
    margin: 0 0 1em 0;
    padding: 0.5em 0;
    font-size: 0.9em;
    width: 100%;
    cursor: pointer;

    .info-step-func{
        p{
            margin: 0;
        }
    }

    .icon-step-func{
    .habitat-icon-box{

        margin: 0.5em 1em 0 1em;
        width: 2em;
        height: 2em;
        }
    }
}

.machine-state{
    margin: 1em 2em 1em 2em;
    height: 70vh;
    overflow-y: scroll;
    font-size: 0.9em;
    padding: 2em !important;
}