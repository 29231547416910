.fig {
  padding: 0px 100px;
}

.marginFig {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  word-wrap: break-word;

  .name-user-space{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 1em 0 0 0;
    word-wrap: break-word;

    span{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      word-wrap: break-word;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      display: inline-block;
    }
  }
}

@media (max-width: 900px) {
  .fig {
    padding: 0px 10px;
  }
}
