.habitat-list-machine {
  margin-top: 1.5em;
  border-top: solid 1px #e9ecef;
  border-bottom: solid 1px #e9ecef;
  height: 6.75em;
  background-color: #ffffff;
  width: 95%;
  padding: 10px 0;
  margin: 1.5em 0 0 1.5em;
}

.habitat-icon-box {
  width: 2.8em;
  height: 2.8em;
  padding: 0.2em;
  border-radius: 5px;
  background-color: #102589;
  margin-left: 1em;
  margin-right: 1em;
  float: left;
}

.mzero {
    margin-top: -15px;
} 
