
//Responsive Variables
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

//Responsive Breakpoints
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}





//iconos
.iconos{
  margin-left: 0.2em;
  margin-top: -2.5rem !important;
  margin-right: 0.5em;
  color:#1D7AFF;
  background-color: white;
  padding: 10px 0 8px 8px;
  width: 30px;
  height: 30px;
 }


.main {
  margin-left: 100px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

.team-profile-img {
  margin-left: 1.5em;
  margin-top: 1.5em;
  border-radius: 4px;
  width: 11em;
  height: 10em;
}

.teams-texto-nav{
  padding: 2px;
  opacity: 0.5;
  font-family: BasisGrotesquePro;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--black);
  margin-top: 2em; 
  margin-left: 0.5em; 
}

.texto-nav-two {
  padding: 12px;
  margin-top: 2em;
  opacity: 0.5;
  font-family: BasisGrotesquePro;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--black);  
}

.teams-nav-side-text {
  font-family: BasisGrotesquePro;
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--black);
  margin-top: 1em;
  padding: 1.5em 1.5em 0.5em 1em !important;
 }

 .teams-nav-side-text-active {
  display: block;
  width: 223px;
  height: 59px;
  border-radius: 4px;
  background-color: var(--gray-200);
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt{
  color: #000668;
  
}

.teams-nav-side-text-two {
  margin-top: 1.5em;
  font-weight: normal;
  font-style: normal;
}

.team-avatar-navbar {
  width: 1.7em;
  height: 1.7em;
  border-radius: 4px;
  background-color: rgba(31, 0, 197, 0.1);
  margin-top: 0em;
  margin-right: 1em;
  margin-left: 1.5em;
}

.bars-menu{
width: 100%;
height: 3.3em;
display: flex;
align-items: center;
background-color:#F5F6FA;
font-size: 1.2em;
color:#1D7AFF;
cursor: pointer;
padding: 0 0 0 1.3em;
}

.bars-menu-extended{
width: 100%;
height: 3.3em;
display: flex;
align-items: center;
background-color:#F5F6FA;
font-size: 1.2em;
color:#1D7AFF;
cursor: pointer;
padding: 0 0 0 1.3em;
}

.bars-menu p{
margin:0;
display: none;
}

.bars-menu-extended p{
margin:0 0 0 1.8em;
color:#47525E;
font-weight: normal;
font-size:0.8em;
}

@keyframes bars-menu-animation{
from{
  width: 5em;
}to{
  width: 10.8em;
}
}

@keyframes sidenav-animation{
from{
  width: 8em;
}to{
  width: 13em;
}
}

.ctn-header {
  width: 80%;
}

.header-kor{
display: flex;
width: 100%;
align-items: center;
justify-content: center;
height: 100%;
color:black;
background-color:white;
border-bottom: 1px solid #E9ECEF;
padding: 0.8em 0;
}




