*{
  outline:none;
}

.folder{
  width: 2.3em;
  height: 2.3em;
  background-repeat:no-repeat;
  background-image: url(../../../images/icons/folder-icon.png);
  background-position: center;
  background-size: cover;
  margin: 0 0.5em 0 0;
  border-radius:4px;
}

.file{
  width: 2.3em;
  height: 2.3em;
  background-repeat:no-repeat;
  background-image: url(../../../images/icons/file-icon.png);
  background-position: center;
  background-size: cover;
  margin: 0 0.5em 0 0;
  border-radius:4px;
}

.git-ctn {
  font-size: 10px;
  color: #888787ee;
  margin: 0em 0 0 5px;
}

.git-img {
  width: 9px;
  font-size: 10px;
  color: #888787ee;
}

.git-ctn:hover, .git-img:hover {
  color: #0f2589;
}

@keyframes SlideDown{
  0% {
      visibility: hidden;
      transform: translateY(-6px)
  }
  100% {
      visibility: visible;
      transform: translateY(0)
  }
}

@keyframes SlideUp{
  0% {
      visibility: visible;
      transform: translateY(0)
  }
  100% {
      visibility: hidden;
      transform: translateY(-6px)
  }
}

.label-info{
  font-size: 0.8em;
}

.funcs-spinner{
  margin: -1em 0 0 1em;
  position: relative;;
}

.tree-file-container{
  margin: 2em 0 0 -40px;
  width: 90%;
  font-size: 0.9em;
}

.functions-list-container{
  display: flex;
  justify-content: center;
  width: 100%;
}

.folder--icon{
  font-weight: bold;
  font-size: 1.08em;

  .icon-repo{
      width: 2.5em;
      height: 2.5em;
      background-color: #0f2589;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 0.2em;
      margin: 0 0.5em 0 0;
  }
}

.delete-repository{
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-radius: 5px;
  font-size: 0.9em;
  opacity: 0;
  color: #dc3545;

  p{
      margin: 0 0 0 0.3em !important;
  }
}

.delete-repository:hover{
  opacity: 1;
}

.file--icon{
  .icon-repo{
      width: 2em;
      height: 2em;
      background-color: #f5f6fa;
      color: #0f2589;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
  }
}

.more-options{
  color: #d5d5d6;
}

.icon-name-repo{
  display: flex;
  align-items: center;
}