.aws-integrate-container{
    background-color:white;
    border: 1px solid rgba(0,0,0,.125);
    margin: 2rem 1rem 0 0;
    padding: 1.5rem;
    height: 1rem;
    overflow: hidden;

    input{
        border:none;
        box-shadow: none;
    }

    

}

.no-collapsed{
    .table{
        margin: 1em 0 1em 0;
        border: 1px solid #E9ECEF;
    }

    .table thead tr{
        border-bottom: 1px solid;
        color: white;
        background-color:  #909aff;
    }

    .table thead th{
        padding: 0.5em 0 0.5em 0.7em;
    }

    .table td{
        padding: 0.5em 0.7em;
    }
}

.verified-check{
    color: #14ca88
}

.no-verified-check{
    color: #dc3545!important;
}

.no-collapsed{
    background-color:white;
    border: 1px solid rgba(0,0,0,.125);
    margin: 2rem 1rem 0 0;
    padding: 1.5rem;
    height: auto;

    input{
        border:none;
        box-shadow: none;
    }
}

.no-border{
    border: none;
    overflow-x: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    margin: -0.6em 0 1em 0;
    font-weight: bold;
    font-size: 1.125em;
    font-family: BasisGrotesquePro, Arial, sans-serif;
    cursor: pointer;

    p:nth-child(1){
        color: #1e7aff;
        text-align: right;
        margin: 0.3em 0.5em 0 0;
        cursor: pointer;
    }
}

.no-border::-webkit-scrollbar {
  display: none;
}

.text-clipboard{
    border: 1px solid rgba(0,0,0,.125);
    height: 10em;
    overflow-x: scroll;
    margin: 1em 0 1em 0;

    p:nth-child(1){
        color: #1e7aff;
        text-align: right;
        margin: 0.3em 0.5em 0 0;
        cursor: pointer;
    }

    p:nth-child(2){
        color: #8e949c;
        padding: 0 0 0 1em;
    }
}

.title-aws{
    font-weight: bold;
    border-bottom: 1px solid rgba(0,0,0,.125);
    padding: 0 0 0.5em 0;
    margin: 0 0 0.5em 0;
    height: 2em;
    cursor: pointer;
}

.title-aws-1{
    border-bottom: 1px solid rgba(0,0,0,.125);
    display: flex;
    justify-content: space-between;
    padding: 0 0 1em 0;
    align-items: center;

    p{
        font-weight: bold;
        margin: 0;
    }

    button{
        font-size: 0.9em
    }
}

.description-aws,
.p-aws{
    margin: 0;
    color: #8e949c;

    span{
        color: #1e7aff;
    }

}

.no-display{
    opacity: 0;
}

