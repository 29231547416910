.habitat-s3-management-container{
    width: 95%;

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }

      .s3bucket-spinner{
         margin: 0.5em 0 0 0; 
      }
}

.management-s3-container{
    display: flex;
    margin: 2em 0 0 0;
    justify-content: space-between;


    p{
        font-size: 1.1em;
        font-weight: bold;  
        width: auto;   
    }

    .options-aws{
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;    
      button{          
        width: 100%;
        margin: 0 0.5em 0 0;
      }
    }    
}

.new-s3-container{
    padding: 1em 0 1em 0;
    border-bottom: 1px solid #E9ECEF;

    .subtitle-s3{
        font-size: 1.1em;
        font-weight: bold;          
    }

    
    display: flex;
    flex-direction: column;

}

.s3-files-container{
    padding: 1em 0 1em 0;
    border-bottom: 1px solid #E9ECEF;

    
    .info-bucket{
        display: flex;
        justify-content: space-between;

        p{
            margin: 0;
        }
    }


    .subtitle-s3{
        font-size: 1.1em;
        font-weight: bold;          
    }

    .file-inspector{
        background-color: #f5f6fa;
        padding: 1em;
        margin: 1em 0 0 0;

        pre{
            margin: 0.3em 0 0 0;
        }
    }

    .text-l{
        margin: 1em 0 0 0;
        font-size: 0.9em;
    }
}

.inputs-new-s3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    input, select{
        width: 50%;
        margin: 0 0.5em 0.5em 0;
    }

    .btn-newRole{
        font-weight: bold;
    }
}

.list-s3-creds{
    border-bottom: 1px solid #E9ECEF;    

    .files-downdrop{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 0 0 -0.5em;  

        p{
            margin: 0;
        }

        svg{
            color: #909aff;
            font-size: 0.9em;
            margin: 0 0 0 0.2em;
        }
    }

    .table{
        margin: 1em 0 1.3em 0;
        border: 1px solid #E9ECEF;        
    }


    .table thead tr{
        border-bottom: 1px solid;
        color: white;
        background-color:  #909aff;
    }

    .table thead th{
        padding: 0.2em;
    }


    .table td{
        padding: 0.5em 0 0 0.5em;
    }
}