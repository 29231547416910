.account-confirmation-error-container{
    background-image: url('../../images/accountconfirmationerror.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 90% 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .error-container{
        background-color: white;
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3em 3em;
        border: 2px solid #f0f0f2;
        margin: 15% 0 0 0;

        .t-error-container{
            font-weight: bold;
            font-size: 1.2em;
            color: #646b6f;
            margin: 0 0 0.5em 0
        }

        .d-error-container{
            color: #646b6f;
            font-size: 0.9em;
        }

        div{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 1em 0;
            text-align: center;

            p{
                margin: 0;
            }

            svg{
                color: #0f2589;
                font-size: 2.8em;
            }

            div{
                flex-direction: column;
            }
        }

        button{
            font-weight: bold;
            margin: 1em 0 0 0;
        }
    }
}

.footer-error{
    margin: 1.5em 0 0 0;
    display: flex;
    flex-direction: column;
    width: 30%;
    font-size: 14px;

    p{
        text-align: center;
        margin: 1.5em 0 18% 0;
        font-size: 0.8em;
    }
}