@import '../variables/app_layout_vars.scss';

.s3-alert {
  margin-top: 1.5em;  
}

.dissmisible-alert {
  width: 100%;
}

.restrictionsText {
  width: 11em;
}

.restrictInfo {
  color: #1d7aff;
}

.popover {
  max-width: 500px;    
}

.popover-body {
  li {
    text-align: left;
    justify-content: left;    
  }  
}

.tr-ctn {
  position: relative;
}

.s3-info {
  position: absolute;
  right: 200px;
  padding: 0px 30px 15px 30px;
  background-color: #f5f5f5;
  border: 1px solid black;
  border-radius: 20px;
  z-index: 300;
}