.notifications-page-box {
	width: 630px;
	border-radius: 4px;
	box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
	border: solid 1px var(--gray-300);
	background-color: var(--white);
	padding: 1.7em 0 0 2em;
	margin-top: 2em;
}

.digest-title {
	font-family: BasisGrotesquePro;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: var(--black);
	margin-bottom: 0.8em;
}

.digest-content {
	font-family: BasisGrotesquePro;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: var(--black);
	margin-bottom: 2em;
}

/*
* Toggle switch
*	Koba Rocks
*/
.checkbox-container {
	display: inline-block;
	position: relative;
	padding-right: 3em;
}
.checkbox-container label {
	background-color: var(--gray-300);
	border: 1px solid #fff;
	border-radius: 20px;
	display: inline-block;
	position: relative;
	transition: all 0.3s ease-out;
	width: 2.7em;
	height: 1.4em;
	z-index: 2;
}

.checkbox-container label::after {
	content: ' ';
	background-color: var(--white);
	border-radius: 50%;
	position: absolute;
	top: 1.5px;
	left: 1px;
	transform: translateX(0);
	transition: transform 0.3s linear;
	width: 20px;
	height: 20px;
	z-index: 3;
}

.checkbox-container input {
	visibility: hidden;
	position: absolute;
	z-index: 2;
}

.checkbox-container input:checked + label + .active-circle {
	transform: translate(-50%, -50%) scale(15);
}

.checkbox-container input:checked + label::after {
	transform: translateX(calc(100% + 0.5px));
}

.checkbox-container.one .active-circle,
.checkbox-container.one input:checked + label {
	background-color: var(--primary-blue);
}

/*
*	Notifications, siwtch contents
*	Koba Rocks dude!
*/
/* Create two equal columns that floats next to each other */
.column-notifications {
  float: left;
  width: 80%;
  padding: 10px;
}

.column-notifications-switch {
  float: left;
  width: 20%;
  padding: 1.5em 0 0 3em;
}
/* Clear floats after the columns */
.row-notifications:after {
  content: "";
  display: table;
  clear: both;
}
