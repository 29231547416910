

.kor-btn {
  background: #EA6F2E !important;
  color: white;
  font-size: 16px;
  width: 14em !important;
}

.kor-btn-sm {
  background: #EA6F2E !important;
  color: white;
  font-size: 16px;
  font-weight: bold;
  height: 2.5em;
  width: 10em !important;
}

.kor-btn:hover {
  background: #cf6329 !important;
  color: white;
}

.center {
  padding-top: 20%;
  text-align: center;
}

.kor-btn-sm:hover {
  background: #cf6329 !important;
  color: white;
}

.init-ctn {
  padding: 20px;
  background: #FBFBFD;
}

.circle-ctn {
  background: #1D7AFF;
  color: white;
  border-radius: 50%;
  padding: 5px 0px;
}

.title-item {
  font-weight: bolder;
  font-size: 1.1em;
  margin-top: 2px;
  margin-bottom: 2px !important;
}

.text-item {
  font-size: 12px;
  margin-bottom: 2px;
}

.click {
  cursor: pointer;
}

.branch-button{
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 0.9em;
  
  
  p:nth-child(1){
    width: 40%;
    padding: 0.5em 4em;
    border-radius: 20px;
    background-color: #F0F1F9;
    margin: 0;
    width: auto;
  }

  p:nth-child(2){
    margin: 1em 0 0 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 1px;
  }
}

.delete-button-kor{
  background-color: #8186A5;
  border: 1px solid #8186A5;
  width: 10em;
}

.delete-button-kor:hover{
  background-color: #959BB5;
  border: 1px solid #959BB5;
}

.details-button-kor{
  background: #cf6329 !important;
  border: 1px solid  #cf6329;
  width: 12em;
  margin: 0 0 0 0.5em;
}

.details-button-kor:hover{
  background: #cf6329 !important;
  border: 1px solid  #cf6329;
  
}

.managed-by-repo{
  display: flex;
  align-items: center;



  p{
    margin: 0;
  }

  .managed-by-text{
    text-transform: uppercase;
    font-size: 0.7em !important;
    font-weight: bold;
    margin: 0 0.5em 0 0;

  }
}

.avatar-repo-author{
  background-color: #F0F1F9;
  display: flex;
  border-radius: 16px;
  padding: 0.2em 0.5em 0.2em 0;
  align-items: center;

 
  p:nth-child(1){
      font-weight: normal;
      font-size: 0.8em;  
      width: 10px;
      margin: 0;
  }

  .name-author{
      margin: 0em 0 0 0.3em;
      font-size: 1.1em;

      p{
          width: 100%; 
      }
  }


  .avatar-1{
      width: 1.3em;
      height: 1.3em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #1D7AFF;
      font-weight: bold;

      p{
          color: white;
          margin: 0;
          
      }

  }
}

.branch-container-clone{
  display: flex;
  align-items: center;

  p{
    margin: 0 0.7em 0 0;
    color: #4C589E;
    font-size: 0.9em;
  }
}

.buttons-delete-view{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  button:nth-child(1){
    width: 8em;
    margin: 0 0.5em 0 0;
  }

  button:nth-child(2){
    width: 12em;
  }
}



.folder-icon-repo{
  display: flex;
  align-items: center;
  justify-content: center;
}

.card{
  border-radius: 12px;
}

.branch-form-control{
  display: flex;
  align-items: center;
  justify-content: center;

  .form-control{
    width: 100%;
    font-size: 0.9em;
    background-color: #F0F1F9;
    margin: 0;
  }
}

.repo-container-kor{
  display: flex !important;
}

.rounded-kor-repo{
  border: 1px solid #E6E8FF;
  border-radius: 16px;
  margin: 1.2em 2em 1.2em 2em;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 2em;
}

.info-repo-kor{
  display: flex;
  width: 30%;

  .folder-icon-repo{
    img{
      margin: 0 2em 0 0;
    }
  }
}
