.subscription-card{
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin: 1em 0.5em 1em 0;
    padding: 1em;
    width: 50%;
    flex-direction: column;

    p{
        margin:0;
    }
    
    p:nth-child(1),
    p:nth-child(4){
        border-bottom: 1px solid #dee2e6;
        margin: 0 0 1em 0;
        padding: 0 0 0.2em 0;
    }

    p:nth-child(4){
        margin: 1em 0 0.5em 0;
    }

    p:nth-child(3){
        font-size: 1.2em;
    } 
}

.cancel{
    color: #f96f71;
    cursor: pointer;
    margin: 0 0 0.5em 0;
    font-size: 0.8em;
}

.indivisualPlan,
    .teamPlan{
        color: white;
        background-color: #1e7aff;
        text-align: center;
        padding: 0.3em 1em;
        width: 9.7em;
        font-weight: bold;
        cursor: pointer;
        border-radius: 4px;
        margin: 0.5em 0 0 0;
        font-size:0.9em;
    }
.indivisualPlan{
        float: left;
    }
.teamPlan{
        float: right;
    }

.subscription-card-tittle{
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 1.125em;
    font-weight:bold;
}

.plans-button-billing{
    display: flex;
    flex-direction: column;
    height: 70%;
    align-items: center;
    justify-content: space-between;

    p:nth-child(1){
        border: none;
        margin: 0.5em 0 0 0;
    }
}