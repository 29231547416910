.welcome-page-container{
    width: 100%;
    height: 100vh;
    padding: 2.2em;
    display: flex;
    flex-direction: column;
    align-items: center;


    .creds-setup-onboarding{
        width: 50%;
        margin: 3em 0 0 0;
        align-items: center;
        justify-content: center;

        input{
            font-size: 0.9em;
            margin: 0.5em 0 0.5em 0;
        }

        .button-authorize-done{
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg{
                color: #13ca88;
            }
        }

        p{
            margin: 0 0.5em 1em 0.5em;
            text-align: center;
        }

        .label-instructions{
            font-size: 0.9em;
        }
    

        button{
            font-weight: bold;
        }
  
        .creds-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button{
                font-weight: bold;
                width: 7em;
                font-size: 0.9em;
                margin: 0.5em 0 0 0;
            }

            .aws-cred-icon svg{
                color: #ff9900;
            }

            .git-cred-icon svg{
                color: #909aff;
            }

            .cred-container{
                width: 50%;
                padding: 1em;
                margin: 0 0 1em 0;
                height: auto;
                border-radius: 10px;
                border: 1px solid #E9ECEF;

                p{
                    margin: 0;
                    text-align: justify;
                }

                p:nth-child(1){
                    font-weight: bold;
                }

                p:nth-child(2){
                    font-size: 0.9em;
                    margin: 0.5em 0;
                }
            }
        }
    }

    .title-welcome{
        font-size: 2em;
        font-weight: bold;
        margin: 2em 0 0 0;
    }

    .description-kor{
        width: 30%;
        text-align: center;
    }

    .container-options{
        width: 80%;
        height: 50%;
        margin: 2em 0 0 0;
        display: flex;

        .option-container:hover{
            border: 1px solid #909aff;
        }


        .option-container{
            color: black;
            cursor: pointer;
            width: 50%;
            margin: 0 0.5em 0 0.5em;
            height: 100%;
            border: 1px solid #E9ECEF;
            border-radius: 10px;
            display: flex;
            align-items: center;
            text-decoration: none;

            a:hover{
                text-decoration: none;
            }

            .image-option{
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    width: 90%;
                }
            }

            .kor-option{
                width: 60%;
                margin: 0 0 0 1em;

                img{
                    width: 80%;
                }
            }

            .info-option{
                width: 50%;
                height: 100%;
                margin: 45% 0 0 0;
                padding: 0 0 0 1em;

                p:nth-child(1){
                    font-size: 1.5em;
                    font-weight: bold;
                }

                p:nth-child(2){
                    width: 70%; 
                }
            }
        }
    }

    .link-documentation{
        font-size: 0.9em;
        margin: 2.5em 0 0 0;
        width: 40%;
        text-align: center;
    }
}