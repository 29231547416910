.payment-card{
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin: 1em 0;
    padding: 1em;
    width: 50%;
    display: flex;
    flex-direction: column;


    p:nth-child(1){
        border-bottom: 1px solid #dee2e6;
        margin: 0 0 1em 0;
        padding: 0 0 0.2em 0;
    }

    p:nth-child(4){
        color: white;
        background-color: #1e7aff;
        text-align: center;
        padding: 0.3em 1em;
        width: 9.7em;
        font-weight: bold;
        cursor: pointer;
        border-radius: 4px;
        align-self: center;
        margin: 0.5em 0 0 0;
        font-size:0.9em
    }

    p:nth-child(2){
        text-align:center;
        width: 100%;
        align-self: center;
        margin: 1.5em 0;
    }

}

.disabled{
    opacity: .65;
    cursor: normal;
}

.payment-card-tittle{
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 1.125em;
    font-weight:bold;
}

.payment-method-card-tittle{
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 1.125em;
    font-weight:bold;
}