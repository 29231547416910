.credit-card-container{
    border: 1px solid #dee2e6;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0.5em 0;
    display: none;

    p:nth-child(1){
        border:none;
        margin:0

    }
}


.cancel-2{
    color: #f96f71;
    cursor: pointer;
    margin: 0 0 0 1em;
    cursor: pointer
}