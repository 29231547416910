.awsCredCard {
    margin-top: 2em;
    margin-right: 2em;
    margin-left: 2em;
    :hover {
        cursor: pointer;        
    }
}

.cardText {
    font-weight: 400;
}

.credIcon {
    position: absolute;
    top: 5%;
    background-color: #102589;
    border-radius: 10%;
    color: #FFFFFF;
    width: 1em !important;
    height: 1em;
    padding: 0.2em;
    font-size: 2em;
}

.cardHeader {
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 1.125em;
    font-weight:bold;
    margin-left: 3em;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
        margin: 0;
    }
}

.form-group{
    width: 95%;
}