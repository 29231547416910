

.alerts-notifications-container{
    padding:2em;
    height: 50%;

    .notifications-container{
        background-color:white;
        border: 1px solid #E9ECEF;
        padding: 1em;
        width: 100%;
    }
}

.title-section{
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 1em;
    font-weight:bold;
    }

.notification-box{
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9ECEF;
    margin: 0.5em 0;
    padding: 0.5em 0;

        .icon-description{
            display:flex;
            align-items: center;
            justify-content: center;

            .icon-notification{
                width: 2rem;
                height:2rem;
                background-color: #F5F6FA;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size:0.9rem;
                border-radius:50%;
                color: #102589;
                margin: 0 0.5rem 0 0;
            }

        }

    p{
        font-weight: normal;
        font-size:0.8rem;
        margin:0;
    }  
    
    span{
        font-weight: bold;
    }
}

