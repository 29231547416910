@import '../variables.scss';

.avatarPicTop {
  width: 2em;
  height: 2em;
  background-color: #1D7AFF;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0.5em 0 0;

  p{
    font-size: 1.1em !important;
  }
}

.avatarTeamPicTop-Left{
  width: 13em;
  height: 12em;
  background-color: #909aff;
  color: $avatar-color;
  padding-top: 7em;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  border: 2px solid white;
  margin-top: 1.5em;
  margin-left: auto;
  margin-right: auto;
}
.font-avatarPicTop-leftMenu{
  margin-top: -0.95em;
  font-size: 8em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
.teamProfileName{
  margin-top: 1em;
  text-align: center;
  text-transform: uppercase;
}

.avatarTeamPicProfile{
  margin-top: -0.1em;
  margin-left: -0.1em;
  width: 6.5em;
  height: 6.5em;
  background-color: #909aff;
  color: $avatar-color;
  padding-top: 3em;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
}
.font-avatarTeamPicProfile {
  margin-top: -0.70em;
  font-size: 4em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  
}

.avatarPicTop-leftMenu {
  //font-family: BasisGrotesquePro;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  width: 2.5em !important;
  height: 2.5em;
  background-color: $avatar-bg-color;
  color: $avatar-color;
  padding-top: 0.25em;
  padding-left: 0.45em;
  padding-right:0.20em;
  border-radius: 5px;
  border: 2px solid white;
  margin-right: 10.7em;
  margin-top: -0.5em;
  margin-left: 1.5em;
}


.leftMenu-Name{
  margin-top: -1.65em;
  margin-left: 3.5em;
}

.avatarCard {
  background-color: $avatar-bg-color;
  width: 7em;
  height: 6em;
  margin-left: -0.6em;  
}

.avtProf {
  background-color: $avatar-bg-color;
  text-align: center;
  font-size: 3em;  
  line-height: 90px;  
}