.row-style{
    height: 100%;
}

.sidebareExp{
    background-color: red;
    height: 100%;
    min-width: 13em;
}

.right-cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main-cont{
    padding: 0em;
    height: 90%;
}

.docs-link {
  float: right;
  z-index: 100;
  margin-right: 1em;
}

.docs-link-ss {  
  font-size: 1.2em;
}

.docs-link-wa {  
  font-size: 1.2em;
}