.user-setting-main{
  position: relative;
  height: 100%;
}

.user-setting-container{
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  display: table;
  width: 100%;

  .error-display{
    font-size: 0.9em;
    font-weight: bold;
    width: 95%;
    margin: 1em 1em 1.5em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    p{
      padding: 0;
      margin: 0;
      height: 100%;
    }
} 
}

.userSetting-box{
  background-color: #ffffff;
  display: table-cell;
  vertical-align: middle;
  padding-top: 5%;
  padding-bottom: 5%;
}

.borderLeft {
  height: 1px;
  background: #dee2e6;;
  width: 100%
}

.d-ctn {
  position: fixed;
  bottom: 0;
  margin-bottom: 5%;
}

.d-text {
  margin-top: 20px;
  font-size: 12px;
  font-weight: normal;
}

.user-setting-subtitle{
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 1.125em;
  font-weight:bold;
}

.title-account-container{
  display: flex;
  align-items: center;
  margin: 0 0 0.5em 0;
  justify-content: space-between;

  .question-mark-documentation-habitat{
    svg{
      margin: 0;
    }
  }

  h6{
    margin: 0;
  }
}

.delete-account-container{
  margin: 0em 0 0 0;
}