.team-page-subtitle-teamprofile {
    margin: 2.5em 0 1em -16em !important;
    text-transform: uppercase;
    font-family: BasisGrotesquePro;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.20;
    letter-spacing: 1px;
    color: #212529;
  }

  .page-box-teamprofile {
    border-radius: 4px;
    box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
    border: solid 1.1px var(--gray-300);
    background-color: var(--white);
    margin: 0 0 0 -13em !important;
  }
  
  .profile-box-size {
      width: 32em;
      padding: 0 0 1em 0em;
  }

  .text.form-text{
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0px 0px 0px;
    margin-bottom: 0px;
  }

  .profile-input-field {
    width: 65% !important;
    padding: 0.4em 1.2em 0.4em!important; 
  }

  .teamprofile-title {
    font-family: BasisGrotesquePro;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--black);
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  .team-profile-box {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    background-color: var(--gray-200);
    padding: 0.6em 0 0 0.6em;
  }

  .teamprofile-img {
    margin-top: 0.2em;
    width: 100px;
    height: 95px;
    border-radius: 4px;
    background-color: var(--white);
  }
  
  