$screen-sm-min: 576px;
$screen-md-min: 992px;
$screen-lg-min: 1000px;
$screen-xl-min: 1300px;

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}



.habitat-roles-management-container{
    width: 95%;

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
}

.role-permission{
    background-color: #f8f9fa;
    height: 25em;
    padding: 1em;
    overflow: scroll;
   

    pre{
        width: 100%;
    }

    @include xl {
        width: 100% !important;
    }

    @include md {
        width: 25em;
    }
}

.role-permission::-webkit-scrollbar{
    display: none;
  }

.checkbox-ctn {
    border: none;

    input {
        box-shadow: none !important;
        width: 20px;
    }
}

.selected-role-cred{
    border-bottom: 1px solid #E9ECEF;

    p{
        margin: 2em 0 0.5em 0;
        font-size: 1.1em;
        font-weight: bold;
    }
}

.inputs-new-role{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    input, select{
        width: 50%;
        margin: 0 0.5em 0.5em 0;
    }

    .btn-newRole{
        font-weight: bold;
    }
}

.new-role-container{
    padding: 1em 0 1em 0;
    border-bottom: 1px solid #E9ECEF;

    p{
        font-size: 1.1em;
        font-weight: bold;    
    }
    
    display: flex;
    flex-direction: column;
}

.list-roles{
    border-bottom: 1px solid #E9ECEF;
    padding: 0 0 1.5em 0;

    .table{
        margin: 0em 0 0 0;
        border: 1px solid #E9ECEF;
    }


    .table thead tr{
        border-bottom: 1px solid;
        color: white;
        background-color:  #909aff;
    }

    .table thead th{
        padding: 0.5em 0 0.5em 0.7em;
    }


    .table td{
        padding: 0.5em 0.7em;
    }
}

.management-roles{
    display: flex;
    margin: 2em 0 0 0;
    justify-content: space-between;

    p{
        font-size: 1.1em;
        font-weight: bold;  
        width: auto; 
    }

    .options-aws{
        width: auto;
        button{
            width: auto;
            margin: 0 0.5em 0 0;
        }
    }
}

