.ctn-template {
  background-color: #fbfbfd;
  color: #0D1A3F;
  height: 100vh;
  min-height: 110%;
  display: flex;
  flex-direction: column;

  .error-display{
    font-weight: bold;
    font-size: 0.9em;
    width: 100%;
    padding: 0 0 0 17%;
    margin: 1em 0 1em 0;

    a{
      color: #ffc107!important;
      text-decoration: underline;
    }

    p{
      margin: 0;
    }
  }

  .btn-outline-primary:disabled{
    color: white;
  }
}

.badge-template {
  background-color: #FF6602;
  color: white;
}

.card-template {
  background-color: #FF6602;
  color: white;
  padding: 15px;
}

.card-template-body {
  border-bottom: none !important;
}

.card-template-button{
  button{
    color: white;
    border: 2px solid white;
    border-bottom: 6px solid white;
    font-weight: bolder;
    border-radius: 5px;
    margin: 0 0em 0 0;
  }

  button:hover{
    background-color: white;
    color: #d65b09;
    border: 2px solid white;
    border-bottom: 6px solid white;
  }
}

.question-mark-documentation-template{
  svg{
    color: white;
    margin: 0 0 0 1em;
  }
}

.border-line {
  height: 2px;
  background-color: white;
  width: 100%;
}

.ctn-img {
  width: 40px;
}

.number-ctn {
  padding: 8px 12px;
  border-radius: 50%;
  background-color: #DADFE9;
  color: white;
} 

.number-ctn-active {
  padding: 8px 12px;
  border-radius: 50%;
  background-color: #FF6602;
  color: white;
}

.line-count {
  height: 1px;
  background-color: #DADFE9;
  width: 30px;
  margin-top: 62%;
}