.box-feature{
   display: flex; 
   border: 1px solid #E9ECEF;
   border-radius: 5px;
   padding: 0.5em;
   font-size: 0.92em;
   margin: 0 0 1em 0;
   cursor: pointer;
}

.box-feature:hover{
    border: 1px solid  #909aff;
}

.box-feature-selected{
    border: 1px solid  #909aff;
}

.habitat-selected{
    border: 1px solid  #909aff;
}

.roles-selected{
    border: 1px solid  #909aff;
}

.funcs-selected{
    border: 1px solid  #909aff;
}

.s3-selected{
    border: 1px solid  #909aff;
}

.dynamo-selected {
    border: 1px solid  #909aff;
}

.templates-selected {
    border: 1px solid  #909aff;
}

.cognito-selected {
    border: 1px solid  #909aff;
}

.api-selected {
    border: 1px solid  #909aff;
}


.box-feature-name{
    display: flex;
    font-weight: bold;
    align-items: center;
    width: 30%;

    p{
        margin: 0;
    }


}

.box-feature-name-icon{
    width: 2em;
    height: 2em;
    background-color: #102589;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0;
}

.box-feature-name-icon-funcs{
    width: 2em;
    height: 2em;
    background-color: #ff875c;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0; 
}

.box-feature-name-icon-templates{
    width: 2em;
    height: 2em;
    background-color:#ff6701;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0;  
}

.box-feature-name-icon-s3{
    width: 2em;
    height: 2em;
    background-color: #13ca88;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0; 
}

.box-feature-name-icon-cognito{
    width: 2em;
    height: 2em;
    background-color: #ad688b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0;    
}

.box-feature-name-icon-api{
    width: 2em;
    height: 2em;
    background-color: #d9a840;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 0.5em 0 0;    
}


.box-feature-plus{
    p{
        margin: 0;
    }

    p:first-child{
        font-weight: bold;
    }

    p:nth-child(2){
        color: #8e949c;
    }

    width: 40%;
}

.box-feature-plus-1{
    text-align: center;

    p{
        margin: 0;
    }

    p:first-child{
        font-weight: bold;
    }

    p:nth-child(2){
        color: #8e949c;
    }

    width: 40%;
}

