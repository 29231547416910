.title-build{
    font-weight: bold;
    font-size: 1.1em;
}

.container-builds-hi{
    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
}

.build-item-box{
    display: flex;
    border: 1px solid #E9ECEF;
    border-radius: 5px;
    padding: 0.5em;
    font-size: 0.92em;
    margin: 0 0 1em 0;
    cursor: pointer;
    align-items: center;
    flex-direction: column;

    .menu-build{
        margin: 0 1em 0 0;
        padding: 0 0.5em 0 0;
        svg{
            color: #a2a1a1
        }
    }

    .flex-dir{
        display: flex;
        align-items: center;
        
    }
    

    .flex-dir-space{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5em 0 1em 0;
    }

    .border-bottom{
        border-bottom: 1px solid #E9ECEF;
    }

    .icon-cicd{
        color: white;
        background-color: #102589;
        width: 2.5em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: 0 0 0 0.5em;
    }

    .info-name-date-cicd{
        margin: 0 0 0 1em;
        
        p{
            margin: 0;
        }

    }

    .info-success-fail-cicd{
        margin: 0 1em 0 0;
        p{
            margin: 0
        }
    }

    .fail-color{
        color: #ff6a5d;
        font-weight: bold;
    }

    .success-color{
        color: #a5fade;
        font-weight: bold;
    }

    .progress-color{
        color: #ffd43b;
        font-weight: bold;
    }

    .more-info-build{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0.5em;
        border-top: 1px solid #E9ECEF;
        margin: 0em 0 0em 0;

        div{
            display: flex;
            align-items: center;
        }

        p{
            margin: 0 0 0 0.3em;
        }

        .info-func-deployed-box{
            display: flex;
            flex-direction: column;
            margin: 0.5em 0em 0 0;

            p{
                font-weight: bold;
                font-size: 0.9em;
                margin: 0 1.7em 0 0;
            }

            button{
                width:100%;
                margin: 0;
            }
        }

    }

    .display-none{
        display: none;
    }

    .executions-build{
        display: flex;
        width: 100%;
        padding: 0 1.3em 0.5em 0.5em;
        align-items: center;
        justify-content: space-between;

        svg{
            color: #909aff;
        }

        p{
            margin: 0;
        }
    }
}



.build-history-items-container{
    width: 93%;   
}

.build-logs-container{
    width: 95%;
}

.build-logs-container-cont{
    width: 45%;
    overflow: scroll;
    position: absolute;
    height: 85%;

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
}