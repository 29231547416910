@import '../variables/app_layout_vars.scss';

.awsCredManageCard {
    margin-top: 1em;
    margin-right: 1em;

    .error-display{
      font-size: 0.9em;
      font-weight: bold;
      width: 95%;
      margin: 1em 1em 1.5em 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      p{
        padding: 0;
        margin: 0;
        height: 100%;
      }
    }
}

.new-cred-btn {    
    margin-top: 1em;
}

.selected-cred {
    padding-top: 1.4em;    
    padding-left: 1.8em;
}

.newCredBtn {
  margin-bottom: 1em;
  float: right;
}

.myDanger {
  color: $alert-red;
  cursor: pointer;
}

.myInfo {
  color: $primary-blue;
  cursor: pointer;
}

.mySuccess {
  color: $gray-500;
  cursor: pointer;
}

.aws-creds-form {
  margin-bottom: 1em;
}

.settings-incard-status-item {
  margin-top: 1em;
}

.build-icon {
  color: #fff;
  font-size: 1em;
  margin-right: 0.5em;
}

.button-loading.spinner-border.spinner-border-sm.text-primary {
  color: #fff !important;
  margin-right: 0.4em;
}

.verified-loading {
  margin-right: 1em;
  color: #0c5460 !important;
}

.redLight {
    color: $alert-red;
    font-size: 1.2em;
    margin-left: 1em;
}

.greenLight {
    color: $green;
    font-size: 1.2em;
    margin-left: 1em;
}

.cred-title{
  font-family: BasisGrotesquePro, Arial, sans-serif;
  font-size: 1.125em;
  font-weight:bold;
}
