.billing-history-containter-1{
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 1em 0 0 1em;
    height: 80%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  

    p:nth-child(1){
        border-bottom: 1px solid #dee2e6;
        width: 95%;
        padding: 0 0 0.2em 0;
    }

    table{
        width: 95%;
        font-size: 0.8em;
        text-align: center;
    }
}

.billing-history-containter-1::-webkit-scrollbar{
  display: none;
}

.table td, .table th{
    border:none;
}

.table thead th{
    border:none
}

.table thead tr{
    border-bottom: 1px solid #dee2e6;
}