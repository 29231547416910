// Google font Lato
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face{ 
  font-family: 'BasisGrotesquePro';
  src: url('basisgrotesquepro.woff') format('woff'),
  url('basisgrotesquepro.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "BasisGrotesquePro", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2A3158;
}

html, body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row {
  margin: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  background-color: #2196F3;
  padding: 10px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid black;
  text-align: center;
  font-size: 40px;
}

/*
* Special atributes
*/
.right-kds-btn {
  position: absolute;
  right: 0px;
  margin: -3.5em 3em 0 0;
  padding: 0.5em 1em 0.5em 1em;
  font-family: BasisGrotesquePro;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--white);
}

.container-kds {
  width: 100%;
  height: 100%;
}

.container-page{
  width: 100%;
  height: 100%;
}

.page-content {
  padding: 0em 0em 0em 0em;
  background-color: var(--gray-200);
  min-height: 100%;
  height:100%;
  width: 100%;
  position: normal;
}

.main-content-app{
  width: 100%;
  height: 100%;
}

.page-nav-main {
  position: relative;
  padding: 0em 1em 0em 0em;
  border: 1;
}

.page-title {
  font-family: BasisGrotesquePro;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--black);
  padding-left: 1em;
  padding-top:-0.5em;
}

.page-form {
  min-width: 30em;
  margin: 0 0 0 1.5em;
  padding: 0em 0em 0em 0em;
}

.page-subtitle {
  margin: 2.5em 0 1em 2em;
  text-transform: uppercase;
  font-family: BasisGrotesquePro;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.20;
  letter-spacing: 1px;
  color: #212529;
}

.page-box {
  border-radius: 4px;
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1.1px var(--gray-300);
  background-color: var(--white);
  margin: 0 0 0 1em;
}

.input-field .form-text {
  font-family: BasisGrotesquePro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--black);
  padding-top: 1em;
}

.input-field .form-input {
  margin: 0;
}

.leftSettingsCol.border-right.col-xl-5.col-lg-5.col-md-5 {
  height: 100vh;
}

.info-ctn-message {
    width: 25em;
    margin: 2em auto 1em auto;
}
