@import '../variables/app_layout_vars';

.avatarBigPic {
  background-color: $avatar-bg-color;
  color: $avatar-color;
  padding-top:0.5em;
  padding-left:0.5em;
  padding-right:0.5em;
  padding-bottom:0.05em;
  border-radius: 5px;
  min-width: 2.2em;
  min-height: 2em;
  border: 2px solid #6c757d;
}

.avatarBigPicTop {
  margin-top: -0.1em;
  margin-left: -0.1em;
  width: 10em;
  height: 10em;
  background-color: #909aff;
  color: #ffffff;
  padding-top: 3em;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: 2px solid #6c757d;
}

.h1B {
  font-size: 3em;
}

