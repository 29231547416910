.modal-kor-container{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color:rgba(171, 180, 190, 0.5);
    z-index:1070;
    display:flex;
    justify-content: center;
    align-items: center;
    top: 0%;
    left:0%;

    .box-modal-options-container{
        width: 30%;
        height: auto;
        padding: 3em;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: solid 1px #e9ecef;

        p:nth-child(1){
            font-weight: bold;
            text-align: center;
            margin: 0 0 0.5em 0;
        }

        p:nth-child(2),
        p:nth-child(3){
            text-align: center;
        }

        .delete-icon{
            color: #ff685c
        }

        .delete-icon-safe{
            color: #14ca88
        }
    }

    .buttons-modal-kor{
        display: flex;

        button{
            margin: 1em 0.5em 0 0;
        }
    }
}

.display-none-kor{
    display: none
}

.modal-delete-repository{
    font-size: 1.1em;
}