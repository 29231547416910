// Colors
$primary-blue:      #1d7aff;
$primary-purple:    #909aff;
$success-green:     #6ef8bd;
$alert-red:         #ff685c;
$gray-100:          #fbfbfd;
$gray-200:          #f5f6fa;
$gray-300:          #e9ecef;
$gray-400:          #81889d;
$gray-500:          #525b76;
$green-1:           #14ca88;
$orange-1:          #ff875c;
$pink-1:            #ff4f8b;
$navy-blue:         #102589;
$dark-blue:         #102053;
$white:             #ffffff;
$black:             #000000;
$light-blue:        #e3ecfa;
$white:             #ffffff;
$black:             ß#000000;

// Map colors to elements
$nav-border-gray: $gray-300;
$sidebar-background-color: $gray-200;
$sidebar-item-background-color: $gray-300;
$dropdown-basic-bg-color: $white;
$dropdown-basic-color: $black;
$button-signin: $primary-blue;
$avatar-color: $white;
$avatar-bg-color: $navy-blue;
$top-logo-border: $gray-300;
