.modal-container-create-habitat{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.671);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;



    .buttons-container{
        width: 100%;
        margin: 2em 0 1em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        button{
            width: 48%;
            margin: 0 0.5em 0 0;
        }
    }
    
    .create-team-form{
        height: auto;
        padding: 1.5em 0 1.5em 0;
        width: auto;

        form{
            .form-group{
                display: flex;
                margin: 0.3em 0 0 0;
                flex-direction: row;
                input{
                    width: 50%;
                    margin: 0 0.5em 0 0;
                }
                select{
                    width: 50%;
                    margin: 0 0.5em 0 0;                    
                }
            }
        }
    }
    .modal-instructions{
        text-align: left;
        width: 80%;
        margin: 1em 0 1em 0em;
        font-size: 0.9em;
    }
    .modal-instructions-1{
        text-align: left;
        width: 85%;
        margin: 1em 0 1em 0em;       
    }
    .modal-instructions-2{
        text-align: left;
        width: 100%;
        margin: 1em 0 1em 0em;      
        font-size: 0.9em; 
    }
    .select-cred-habitat{
        font-size: 0.9em;
    }
    .habitat-name-modal{
        width: 80%;
        input{
            width: 100%;
            font-size: 0.9em;
        }
        select{
            font-size: 0.9em;
        }
    }
}

.button-authorize-aws{
    font-size: 0.9em;
    align-self: start;
}

.error-create-habitat{
    text-align: left;
    width: 80%;
    
    p{
        margin: 0 0 0.5em 0;

    }
}

.show-create-habitat{
    display: none;
}