.content-section {
    font-size: 11px;
    text-align: center;
}

.logo-center {
    display: block;
    margin: auto auto;
    padding-bottom: 1em;
}

.footer-settings {    
    width: 80%;
    position: absolute;
    bottom: 0;
    margin-bottom: 30%;
    left: 10%;
  }