.b-team-list-container{
    padding: 0 2em;
    height: 50vh;
}

.team-subscription-tittle{
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 1.125em;
    font-weight:bold;
}

.card-team-subscription{
    display: flex;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 1em;
    align-items: center;
    width: 100%;
    font-weight: bold;
    margin: 0.5em 0;

    .avatar-team{
        width: 20%;
    }

    .name-team{
        width: 70%;
    }

    .type-sub{
        width: 15%;
    }

    .avatarPicTop{
        margin: 0;
    }

    p{
        margin:0;
        text-transform: capitalize;
    }
}

.card-team-subscription-active { 
    display: flex;
    border: 1px solid #1d7aff;
    border-radius: 5px;
    padding: 1em;
    align-items: center;
    width: 100%;
    font-weight: bold;
    margin: 0.5em 0;
    cursor: pointer;

    .avatar-team{
        width: 20%;
    }

    .name-team{
        width: 70%;
    }

    .type-sub{
        width: 15%;
    }

    .avatarPicTop{
        margin: 0;
    }

    p{
        margin:0;
        text-transform: capitalize;
    }
}

.billing-teamsub-box{
    height: 80%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;
}

.billing-teamsub-box::-webkit-scrollbar{
  display: none;
}
