.billing-row {
    padding: 0 0 0 1em;
    display: inline-block;
    /*padding-left: 3em;*/
  }
  
  .billing-history-row {
      margin: 1em 0 0 1em;
  }
  
  .billing-page-box {
      width: 55em;
  }
  
  .page-box.billing-plan-page-box {
      padding-right: 2.5em;
      padding-left: 0.5em;
      margin-right: 3em;
      min-width: 260px;
      min-height: 330px;
  }
  
  .billing-column-left {
      min-width: 5em;
      padding-right: 3em;
  }
  
  .billing-column-text-normal {
      opacity: 0.8;
      font-family: BasisGrotesquePro;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: var(--black);
  }
  
  .billing-column-text-info {
      font-family: BasisGrotesquePro;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: var(--black);
  }
  
  .billing-column-empty {
      min-width: 13em;
  }
  .billing-column-right {
      min-width: 5em;
  }
  .billing-column-last {
      min-width: 5em;
      align-items: center;
  }