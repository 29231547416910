

.functions-container-overview{
    padding:0em 0 0 0;
    height: 95%;

    .title-section{
        font-family: BasisGrotesquePro, Arial, sans-serif;
        font-size: 1em;
        font-weight:bold;
    }

    .aditional-info-repo-box1{
        display: flex;
        margin: 0.5em 0 0 0;
        align-items: center;
    

        p:nth-child(1){
            margin: 0 0.5em 0 0;
            text-transform: uppercase;
            font-size: 0.7em !important;
            font-weight: bold;
            color: #2A3158;
            width: 15%;
        }

        p:nth-child(2){
            color: #8186A5;
            font-size: 0.8em !important;
        }
    }

    .span-text-box{
        color: #2A3158;
    }

    .aditional-info-repo-box{
        display: flex;
        margin: 0.5em 0 0 0;

        p:nth-child(1){
            margin: 0 0.5em 0 0;
            text-transform: uppercase;
            font-size: 0.7em !important;
            font-weight: bold;
            color: #2A3158;
            width: 15%;
        }

        p:nth-child(2){
            color: #8186A5;
            font-size: 0.8em !important;
            width: 75%;
        }  
        
        .avatar-repo-author{
            background-color: #F0F1F9;
            display: flex;
            border-radius: 16px;
            padding: 0 0.5em 0 0;
           
            p:nth-child(1){
                font-weight: normal;
                font-size: 1.1em;
                width: auto;
            }

            .name-author{
                margin: 0em 0 0 0.3em;
                font-size: 1.1em;
                width: 100%;

                p{
                    width: 100%; 
                }
            }


            .avatar-1{
                width: 1.7em;
                height: 1.3em;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #1D7AFF;
                font-weight: bold;

                p{
                    color: white;
                    margin: 0;
                    
                }

            }
        }
    }

    .function-box{
        display:flex;
        flex-direction: column;
        justify-content: center;
        justify-content: space-between;
        margin: 1em 0 0 0;
        padding: 0 0 1em 0;
        border-bottom:1px solid #F5F6FA;

        p{
            margin:0;
            font-weight:normal;
            font-size: 0.9rem;
        }

        .icon-function-container{
            display: flex;
            align-items: center;

            p:nth-child(1){
                font-weight: bold;
                color: #4C589E;
                font-size:1rem;
            }

            p:nth-child(2){
                font-size:0.8rem;
            }

            .function-icon{
                background-color: #1D7AFF;
                width: 1.8em;
                height: 1.8em;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                border-radius:50%;
                margin: 0 0.5em 0 0;


                img{
                    width: 2.1em;
                    height: 2.1em;
                }
        
            }
        }
    }
}

.status-functions{
    display: flex;
    align-items: center;

    p{
       padding: 0 0 0 0.5em;
    }
}

.greenLight-2{
    color:#6EF8BD;
}

.alert-color{
    color:#FF685C;
}

.overview-funcs-box{
    height: 70%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.overview-funcs-box::-webkit-scrollbar{
  display: none;
}
