.invitation-container{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.9em 0;

    p{
        margin:0;
    }

    .buttons-invitation-v2{
        margin: 0 0.7em 0 0;

        button{
            width: 7em;
            margin: 0 0.5em 0 0;
        }
    }
}

.buttons-invitation{
    margin: 0.5em 0 0 0;
    display: flex;

    .accept-reject-button{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 1em 0 0;
        cursor: pointer;
        font-weight: bold;
        justify-content: center;
    }

    .invitation-label{
        color: black !important;
        font-size: 0.9em;
    }

    p{
        margin: 0;
    }
    
}

.accept{
   color: #6ef8bd; 
   font-size: 0.9em;
}

.cancel-invitation{
    color: #f6685c;
    font-size: 0.9em;
}

.icon-team{
   color: #0f2489;
   background-color: #f5f6fa;
   width: 2em;
   height: 2em;
   border-radius: 50%;
   align-items: center;
   display: flex;
   justify-content: center;
   margin: 0 1em 0 0;
}

.name-team-invitation{
    display: flex;
    align-items: center;

    p:nth-child(2){
        margin: 0 0 0 0.5em;
        color: black !important;
    }
}

.invitations-list{
    border: 1px solid #f6f7f8;
    padding: 0.5em 0 0.5em 1em;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.invitations-list::-webkit-scrollbar{
  display: none;
}
