.form-control {
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  margin: 0 0 0.8em;
  color: black;
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}