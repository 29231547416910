.menu-container-kor {
  position: fixed;
  z-index: 100;
}

.open-navbar{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(42, 49, 88, 0.5); 
  z-index: 100;
}


.display-menu-initial{
  display: none;
}


.pin-sidebar{
  display: flex;
  align-items: center;
  margin: 2em 0 0 0;
  width: 100%;
  justify-content: center;
  display: none;

  .icon-sidebar{
    background-color: #F0F1F9;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }

  p{
    margin: 0;
    font-weight: bold;
    color: #2A3158;
    margin: 0 0 0 1em;
    font-size: 1.07em;
  }
}

.copyrights-text{
  color: #8186A5;
  font-size: 0.7em;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 3em 0 0 0;
  align-items: center;
  text-align: center;

  p{
    width: 85%;
  }

}

.menu-kor-sidebar{
  background-color: white;
  width: 25%;
  height: 100%;
  padding: 1.4em 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  z-index: 100;

  .container-menu-sections{
    width: 100%;
  }

  .menu-kor-sections{
    margin: 1.3em 0 0 0;
    width: 100%;

    .menu-title{
      font-size: 1.5em;
      font-weight: bolder;
    }

    .section-menu{
      font-size: 1.1em;
      border-bottom: 2px solid #F0F1F9;
      width: 100%;
      padding: 1em 0;

      p{
        margin: 0;
        color: black;
      }

      a{
        text-decoration: none;
      }

      p:hover{
        color: #909aff !important;
      }
    }
  }


  .header-kor-logo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color:black;
    background-color:white;


    .logo-line-close{
      display: flex;
      align-items: center;
    }

    .app-logo {
      margin: 0 0 0 0.6em;
      width: 1.8em;
    }

    svg{
      color: #909AFF;
      font-size: 1.5em;
    }

    .closeIcon{
      cursor: pointer;
    }

    .line{
      height: 30px;
      width: 2.5px;
      border:  1px solid #1D7AFF;
      background-color: #1D7AFF;
      border-radius: 50px;
      margin: 0 0 0 1.8em;
      opacity: 1;
    }
    .form-control{
      margin: 0;
      background-color: #F0F1F9;
      width: 100%;
    }
    .select-teams{
      display: flex;
      align-items: center;
      margin: 0 0 0 2em;
      width: 60%;
      p{
        margin: 0 1em 0 0;
        color:#1D7AFF;
        font-size: 0.9em;

      }
    }

  }
}

.sidebar-line {
  border-bottom: 0.1em solid #d5d5d6;
  width: 3.5em;
  margin: 0em 0.5em;
}

.extended-sidebar-line {
  border-bottom: 0.1em solid #d5d5d6;
  width: 11.5em;
  margin: 0em 0.5em;
}

.nav-side-section {
  font-size: 1.5em;
  background-color: white;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #102589;
  margin: 0.5em 0em 0.5em 0;
  font-size: 1.3em;
}

.nav-side-section-template {
  font-size: 1.5em;
  background-color: #ff6602;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  margin: 0.5em 0em 0.5em 0;
  font-size: 1.3em;
}

.nav-section-container {
  display: flex;
  align-items: center;
  margin: 1.2em 0 0 1em;
  padding: 0em;
}

.active p{
  border-left: 4px solid #909aff;
  padding: 0 0 0 0.5em;
}

.hidden-label p {
  display: none;
  margin: 0 0 0 1em;
  font-size: 1em;
  font-weight: normal;
}

.display-label p {
  display: inline;
  margin: 0 0 0 1em;
  font-size: 1em;
  font-weight: normal;
}

.margin-nav {
  margin: 2em 0 0 0;
}

.dropdown-item {
  display: block;
}

.all-section {
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}

.terms-and-services-label {
  text-align: center;
  margin: 1.1em 0 0 0;
  color: #47525e;
  font-size: 0.7em;
  text-transform: uppercase;

  p {
    margin: 0;
  }
}

.animate__animated.animate__slideInLeft,
.animate__animated.animate__slideOutLeft
.animate_animated.animate__rotateIn{
  --animate-duration: 600ms;
}