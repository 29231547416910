//Responsive Variables
$screen-sm-min: 300px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

//Responsive Breakpoints
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

.ui-options {
  width: 1440px;
  height: 1024px;
  background-color: var(--gray-100);
}

.Shape {
  width: 24px;
  height: 26px;
  background-color: #7289da;
}
.Bg {
  margin-top: -1em;
  width: 500px;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0 12px 12px -10px rgba(13, 25, 63, 0.12);
  border: solid 1px var(--gray-300);
  background-color: var(--white);
}

.invitations-container{
  width: 100%;
  overflow-x: scroll;
  display: flex;
}

.invitations-container::-webkit-scrollbar{
  display: none;
}


.team-invitations-box{
  margin: 0 1em 2em 0;

  .invitation-container-team{
    border: 1.2px solid #F0F1F9;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    margin: 0 1em 0 0;

    .avatar-invite{
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #909AFF;
      
      img{
        width: 2.3em;
        height: 2.3em;
      }
    }

    .buttons-invite{
      display: flex;
      width: 100%;
      margin: 1em 0 0 0;

      button{
        width: 70%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.8em;
        letter-spacing: 1px;
      }

      button:nth-child(1){
        margin: 0 0.5em 0 0;
        background-color: #8186A5;
        border-color: #8186A5;
      }

      button:nth-child(2){
        background-color: #EA6F2E;
        border-color: #EA6F2E;
      }
    }

    .details-invite{
      margin: 1em 0 0 0;

      p{
        margin: 0;
        text-align: center;
      }

      p:nth-child(1){
        font-weight: bold;
        font-size: 1.1em
      }

      p:nth-child(2){
        color: #8186A5;
        font-size: 0.9em;
      }
    }
  }

  .teams-box-title-mark{
    font-size: 1.4em;
  }
}

.team-page-box{
margin: 0em 0 0 0em;
height: 100%;
padding: 0 0 2em 0;

  .team-page-box p{
    display: none;
  }

  .error-display{
    font-size: 0.9em;
    font-weight: bold;
    width: 95%;
    margin: 1em 1em 1.5em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    p{
      padding: 0;
      margin: 0;
      height: 100%;
    }
  }

  .teams-box-title-mark{
    display: flex;
    align-items: center;
    margin: 0 0 1em 0;
    justify-content: space-between;

    p{
      margin: 0;
      font-size: 1.4em;
    }
  }
}

.team-list-boxes{
  height: auto;
  padding: 0 0 2em 0;
}

.team-list-boxes::-webkit-scrollbar {
  display: none;
}

.orgz{
  padding: 0.2em 1em 1em 1.7em;
}

.team-page-title {
  font-family: BasisGrotesquePro;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--black);
  padding-left: 1em;
  padding-top:-0.5em;
}

.team-page-subtitle {
  margin: 2.5em 0 1em -15.5em !important;
  text-transform: uppercase;
  font-family: BasisGrotesquePro;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.20;
  letter-spacing: 1px;
  color: #212529;
}

/* CREATE TEAM BUTTON*/

.teamcard {
  width: 30em;
  margin-top: 1em !important;
  margin-left: -13em;
  margin-bottom: 0.5em;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.team-card-header {
  width: 30em;
  height: 2.8em;
  border-radius: 0.4em !important;
  display: block;
  color:#1D7AFF;
  font-weight: 600;
  padding: 0.5em 1em;
  margin-bottom: 0 !important;
  background-color: white;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.125);
}

.card-header:hover {
  z-index: 1;
  color: #0056b3;
  text-decoration: none;
  background-color: #f8f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}


.team-card-header-title{
  margin-top: 0.1em;
}

.fa-user-plus {
  margin-left: 1.4em;
  margin-right: 7em;
}


.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.input-field-team {
  margin-top: -0.5em;
  margin-left: -5em;
}

.collapse:not(.show) {
  margin-top: 1em;
  display: none;
}

.team-input-field {
  width: 90% !important;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-field-email {
  margin-top: -0.5em;
  margin-left: -5em;
}

.email-input-field {
  width: 90% !important;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn-team-submit {
  margin-top: 0.5em;
  margin-left: 3em;
  width: 7em;
  height: 2.5em;
}

.form {
  margin: 8em 0 0 50%;
}

.form-group input{
  margin: 0 0 0.5em 0;
}

.list-group-item{
	list-style: none;
	margin: 0;
	padding: 0.5em;
	display: flex;
}


.container{
	margin-left: 0em;
}

.team-Name {
	padding: 0.15em;
}
.length-name{
  display: block;
  width: 7em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: white;
}

.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.team-Role {
	justify-content: center;	
	padding: 0.15em;
	border-radius: 4px;
  display: flex;
}
.background-role {
	text-align:center;
	background-color: var(--gray-200);
	width: 6em;
	height: 1.8em;
	font-size:normal;
	border-radius: 25em;
}
.fa-eye{
	margin-right: 0.5em;
	margin-left: 0.5em;
}
.team-link {
	font-weight: bold;
  margin-top: 0.2em;
  text-decoration: none;
}
.team-link a:hover {
  color: #0056b3;
  text-decoration: none;
}

.teams-container{
  background-color:var(--gray-200);
  width: 100%;
  height:100vh;
  margin: 0em 0 0 0em;

  .title-section-ui{
    margin: 0 !important;

    button{
      width: 200px;
      height: 3em;
      font-size: 0.9em;
      background-color: #EA6F2E;
      border-color: #EA6F2E;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 1em 0 0;
    }
  }

}

.title-teams{
  margin: 2em 0 0 2em;
}


.team-accordion{
  padding: 0 0 0 15em;
}

.alert-danger{
  width: 90%
}


.team-list-container,
.team-management-container{
  width: 100%;
  background-color:white;
}

.team-management-container{
  border-right: 1px solid #E9ECEF;
  padding: 0 2em 2em 2em;
  margin: 0em 0 0 0;
}

.management-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .management-container-buttons{
    width: auto;
    display: flex;
    flex-direction: column;

  }

  
}

.team-list-container{
  background-color: white;
  padding: 2em;
  width: 100%;
}

.title-button-m{
  display: flex;
  justify-content: space-between;
  margin: 0 0em 0 0;
  align-items: center;

  p{
    font-size: 1.1em;
  }
}

.button-flux{
  background-color: #1D7AFF;
  color: white;
  padding: 0.5em 0em;
  border-radius:4px;
  width: 11em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  p{
    margin:0;
    font-size: 0.8em !important;
  }

}

.teams-list-management-container{
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction:row;
  background-color: #fbfbfd;
}


.management-container{
  display:flex;
  margin: 1em 0;
  border: 1px solid #E9ECEF;
  width: 95%;

  @include sm{
    flex-direction: column-reverse;
    padding: 1em;
  }

  @include md{
    flex-direction: column-reverse;
    padding: 1em;
  }

  @include lg{
    flex-direction: row;
    padding: 0 0 0 1em;
  }

  @include xl{
    flex-direction: row;
    padding: 0 0 0 1em;
  }
}

.plan-upgrade-options{
  width:60%;
  background-color: white;
  color:#47525E;

  @include sm{
    width:100%;
  }

  @include md{
    width:100%;
  }

  @include lg{
    width:60%;
  }

  @include xl{
    width:60%;
  }
}

.other-option{
  width:40%;
}

.plan-box{
  width: 90%;
  display: flex;
  margin: 1em 1em;
  justify-content: space-between;
  padding: 1em;
  border: 1px solid #E9ECEF;
  align-items: center;
  text-align: center;

  p{
    margin:0;
    width:80%;

    @include sm{
      font-size: 0.7rem;
    }
  
    @include md{
      font-size: 0.7rem;
    }
  
    @include lg{
      font-size: 0.9rem;
    }
  
    @include xl{
      font-size: 0.9rem;
    }
  }

  @include sm{
    flex-direction:column;
  }

  @include md{
    flex-direction:column;
  }

  @include lg{
    flex-direction:row;
  }

  @include xl{
    flex-direction:row;
  }
}

.other-options{
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-items: center;

  .button-flux{
    margin: 0 0 0.5em 0;

    @include sm{
      width: 100%;
    }
  
    @include md{
      width: 100%;
    }
  
    @include lg{
      width: 11em;
    }
  
    @include xl{
      width: 11em;
    }
  }
}

.team-box{
  background-color:white;
  border: 1px solid #E9ECEF;
  width: 100%;
  padding: 1.5em 1em;
  display: flex;
  align-items: center;
  margin:1em 0;
  height: auto;
  border-radius: 10px;
  justify-content: space-between;
}

.team-name-avatar{
  display:flex;
  align-items: center;
  width: 20%;

  p{
    margin:0 0 0 0.5em;
    color: #47525E;
    font-weight: bolder;
  }
}

.buttons-team-m{
  button{
    width: 9em;
  }
  button:nth-child(1){
    background-color: #8186A5;
    border-color: #8186A5;
    margin: 0 0.7em 0 0;
  }

  button:nth-child(2){
    background-color: #EA6F2E;
    border-color: #EA6F2E;
  }

  .btn-primary.disabled, .btn-primary:disabled{
    background-color: #FBFBFD;
    border-color: #FBFBFD;
  }

}

.team-role{
  width: 15%;

  p{
    margin:0;
    background-color: #E5EEFA;
    color:#969FAA;
    font-weight: normal;
    padding:0.3em 1.2em;
    border-radius: 80px;
    font-size:0.7em;
    text-align: center;
  }
}

.info-team-avatar{
  margin: 0 0 0 1.4em;
  p{
    font-weight: normal;
  }

  .team-name{
    font-weight: bold;
    font-size: 1.1em;
    margin: 0;
  }

  .team-last-updated{
    text-transform: uppercase;
    color:#8186A5;
    font-weight: bold;
    font-size: 0.7em;
    letter-spacing: 0.5px;
    margin: 0.5em 0 -0.5em 0;

    span{
      color: #2A3158;
    }
  }

  .owner-team{
    display: flex;
    font-weight: bold;
    font-size: 0.7em;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0.7em 0 0 0;
    align-items: center;

    p{
      font-weight: bold;
      margin: 0;
    }

    div{
      display: flex;
      margin: 0 0 0 1em;
      background-color: #F0F1F9;
      align-items: center;
      justify-content: center;
      border-radius: 20px;

      p{
        background-color: #F0F1F9;
      }

      p:nth-child(1){
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1D7AFF;
        color: white;
        border-radius: 50%;
      }

      p:nth-child(2){
        padding: 0 1em;
        border-radius: 20px;
      }
    }
  }
}

.avatar-team{
  background-color: #909AFF;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  img{
    width: 2.4em;
    height: 2.4em; 
    border-radius: 50%;
  }
}

.team-role-members{
  display: flex;

  .members-count{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1em 0 0;

    p:nth-child(1){
      background-color: #F0F1F9;
      padding: 1em 2em;
      border-radius: 20px;
      font-size: 0.7em;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      width: 9em;
      text-align: center;
    }

    p:nth-child(2){
      font-weight: bold;
      font-size: 0.7em;
      text-align: center;
      margin: 0.7em 0 0 0;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  p{
    margin: 0;
  }
}

.team-toggle{
  width: 45%;
  display: flex;
  justify-content:flex-end;





}

.teams-members-alerts-containter{
  background-color:white;
  padding: 0em;
  display: flex;
  height: 45% !important;

  @include sm{
    flex-direction:column;
    height:auto;
    margin: 0 0 0 -6em;
  }

  @include md{
    flex-direction:column;
    height:auto;
    margin: 0 0 0 -6em;
  }

  @include lg{
    flex-direction:row;
    margin: 0;
  }

  @include xl{
    flex-direction:row;
    margin: 0;
  }
}

.team-members-container{
  width: 50%;
  padding: 2em 0 0 2em;

  @include sm{
    width: 100%
  }

  @include md{
    width: 100%;
  }

  @include lg{
    width: 50%;
  }

  @include xl{
    width: 50%;
  }
}

.team-members-cont{
  height: 100%;
}

.team-members-container{
  .table{
    width: 95%;
    font-size:0.8em;

    @include sm{
      width: 100%;
      font-size:0.6em;
    }
  
    @include md{
      width: 100%;
      font-size:0.6em;
    }
  
    @include lg{
      width: 90%;
      font-size:0.8em;
    }
  
    @include xl{
      width: 95%;
      font-size:0.8em;
    }

    th{
      border:none !important;
    }
  }

  
}

.user-icon{
  color: #102589;

}

.switch{
  border-radius: 20px;
}

.modal-container{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color:rgba(171, 180, 190, 0.5);
  z-index:1002;
  display:flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  left:0%;
}

.create-team-form{
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 35%;
  text-align: center;

  @include sm{
    width: 90%;
  }

  @include md{
    width: 90%;
  }

  @include lg{
    width: 35%;
  }

  @include xl{
    width: 35%;
  }

  .modal-title{
    font-size: 1.1em;
    margin: 0 0 0.5em 0;
    font-weight:bolder;
  }

  form{
    display: flex;
    width: 80%;
    margin:0;

      .form-group{
        display: flex;
        flex-direction: column;
        width: 100%;

        input{
          width: 100%;
          border-radius:4px;
          font-size:0.9em;
          margin: 0.5em 0 0 0;
        }

        button{
          width: 100%;
        }
      }
  }
}

.cancel-word{
  font-weight: normal;
  cursor: pointer;
  color: #102053;
  margin:1em 0 0 0;
}

.cancel-word:hover{
  text-decoration: underline;
}

.buttons-container{
  display: flex;
  font-weight: bold;
  color:white;
  justify-content: space-between;
  margin: 0.7em 0 0 0;

   p{
      background-color: #1D7AFF;
      cursor: pointer;
      width: 49%;
      border-radius: 4px;
      padding: 0.5em 0;
      font-size:0.9em
    }


}

.buttons-container-2{
  display: flex;
  font-weight: bold;
  color:white;
  justify-content: space-between;
  margin: 0.7em 0 0 0;

   p{
      background-color: #1D7AFF;
      cursor: pointer;
      width: 100%;
      border-radius: 4px;
      padding: 0.5em 0;
      font-size:0.9em
    }


}

.buttons-container-3{
  display: flex;
  font-weight: bold;
  color:white;
  justify-content: space-between;
  margin: 0.7em 0 0 0;

   p{
      background-color: #1D7AFF;
      cursor: pointer;
      width: 100%;
      border-radius: 4px;
      padding: 0.5em 0;
      font-size:0.9em;
      margin: 0 0.5em 0 0;
    }


}

.members-list{
  background-color: white;
  border:1px solid #E9ECEF;
  width: 100%;
  display: flex;
  padding: 1em;
}

.notifications-container{
  background: #fbfbfd;
  width: 70%;
  height: 80%;
  color: #fbfbfd;
}

.notifications-container-1{
  background: #fbfbfd;
  width: 50% !important;
  height: 80%;
  color: #fbfbfd;

}

.display-none{
  display:none;
}

.display-modal{
  display:flex;
}

.disabled{
  opacity: 0.65;
}

.disabled-card{
  z-index:100;
  position: absolute;
  height: 5em;
  width: 13em;
}

.display-members{
  display: normal;
}

.empty-div{
  color: #c7cfd1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-teams{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-teams p{
  margin: 0;
}

.error-teams-none{
  display: none;
}

.icon-close{
  cursor: pointer;
}

.button-teams-management{
  margin: 0.3em 0.5em 0.3em 0;
}