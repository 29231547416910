.onboarding-container{
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
}

.onboardingClass{
    z-index: 1005;
    position: relative;
    background-color: white;
    padding: 0.5em;
    border-radius: 4px;
}

.onboardingClass-0{
    z-index: 1005;
    position: relative;
    background-color: white;
    border-radius: 4px;
}

.onboardingClassHabitat-0{
    z-index: 1005;
    position: relative;
    border-radius: 4px;
}

.onboardingClassHabitat{
    z-index: 1005;
    position: relative;
    background-color: white;
    padding: 0.5em 0 1em 0.7em;
    border-radius: 4px;
}

.onboardingClassRoles{
    z-index: 1005;
    position: relative;
    background-color: white;
    padding: 0;
    border-radius: 4px;
}

.onboardingClassAWS{
    z-index: 1005;
    position: relative;
    background-color: white;
    padding: 0em;
    border-radius: 4px;
}

.onboardingClassFuncs{
    z-index: 1005;
    position: relative;
    background-color: white;
    padding: 0;
    border-radius: 4px;
}

.card-01-onboarding{
    width: 25em;
    padding: 0 0 1.5em 0;
    background-color: white;
    border-radius: 10px;

    .btn-primary{
        width: 100%;
    }
    
    .btn-outline-primary{
        width: 50%;
    }

    a{
        width: 40%;
        margin: 0 0.5em 0 1em;
    }
}

.card-02-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}


.card-03-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-04-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-05-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-06-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-07-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-08-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;


    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-09-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 1em;
    bottom: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-10-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 1em;
    left: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-11-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 1em;
    left: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-12-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 1em;
    left: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-13-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 1em;
    left: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-14-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 1em;
    right: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.card-13-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 1em;
    left: 1em;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}


.card-15-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    margin: 0% 0 0 0%;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    } 
}

.card-16-onboarding{
    width: 25em;
    padding: 1.5em 1em 1.5em 1em;
    background-color: white;
    border-radius: 10px;
    margin: 0% 0 0 0%;

    .btn-link{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: left;
    } 
}

.spinner-container{
    display: flex;
    align-items: center;
    margin: 0 0 1em 0;


    .spinner-border{
        width: 1em;
        height: 1em;
        color: #909aff !important;
    }

    
    p{
        margin: 0 0 0 0.5em;
        font-weight: bold;
        color: #909aff !important;
        font-size: 0.97em;
    }
}

.repository-spinner{
    margin: 0 0 1em 2.5em;
}


.next-onboarding{
    display: flex;
    width: 100%;
    align-content: space-between;
    justify-content: space-between;

    .button-2{
        text-align: right;
    }
    
}

