.checkout-container-c{
    width: 100%;
    margin: 0em;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
}




.card-container{
    width: 95%;
    border: 1px solid #dee2e6;
    height: auto;
    padding: 2em;
    margin: 70% 0 0 0;
}

.toast {
    max-width: 100% !important;
}

.toast-body {
    padding: 0 !important;
}

.alert {
    margin-bottom: 0 !important;
}

.capitalize {
    text-transform: capitalize;
}

.custom-check {
    height: 20px !important;
    box-shadow: none !important;
}

input,
.StripeElement {
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  margin: 0 0 0.8em;
  color: black;
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.title-shipping,
.title-pay{
    font-weight: bold;
    font-size: 1.1em;
}

button{
    color: white;
    background-color: #1d7aff;
    border:none;
    font-weight: bolder;
    padding: 0.3em 0;
    width: 12em;
    border-radius: 4px;
}

form{
    display: flex;
    flex-direction: column;
    align-items:center;
}



.plan-container{
    background-color:white;
    border: 1px solid #dee2e6;
    padding: 3em 5em;
    display:flex;
    justify-content:center;
    flex-direction:column;
    width: 100%;
    margin: 2em 0 0 0em;

    p:nth-child(1){
        font-size: 1.3em;
        font-weight: bold;
        border-bottom: 1px solid #dee2e6; 
        padding: 0 0 0.5em 0;  
        text-transform: capitalize;
    }

    p:nth-child(2){
        color: #bec4cb;
        font-size: 0.9em;
    }

    p:nth-child(3){
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
    }

    p:nth-child(4){
        font-weight: bold;
    }
    
    p:nth-child(7){
        border-bottom: 1px solid #dee2e6;
        padding: 0 0 1.1em 0;  
    }
    
    .subtotal-top-features{
        border-top: 1px solid #dee2e6; 
        font-size: 1.2em;
        margin: 0em 0 0 0;
        padding: 0.5em 0 0 0;
        align-self: flex-end;
        width: 100%;
        text-align: right;
    }

    .subtotal {
        font-size: 1.2em;
        margin: 0em 0 0 0;
        padding: 0em 0 0 0;
        align-self: flex-end;
        width: 100%;
        text-align: right;
    }

    .taxes-checkout{
        font-size: 1.2em;
        margin: 0;
        text-align: right;
    }

    .total-checkout{
        font-size: 1.5em;
        font-weight: bold;
        text-align: right;
        margin: 1em 0 0 0;
    }
}

.main-content-billing-checkout{
    display: flex;
    background-color: #fbfbfd;
    height: 100%;

    .leftSettingsCol{
        margin: 0;
        width: 45%;
    }

    .rightSettingsCol{
        width: 55%;
        margin: 0em;
        display: flex;
        justify-content: center;

        .container-form-checkout{
            width: 80%;
            margin: 2em 0 2em 0;
        }

        .container{
            padding: 0;

            .p-format{
                font-size: 1.1em;
            }
        }

        .p-66{
            padding: 2em;
        }
    }


}

.footer-checkout{
    margin: 2em 0 0 0;
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    font-size: 14px;

    img{
        width: 2em;
    }

    p{
        text-align: center;
        margin: 1.5em 0 18% 0;
        font-size: 0.8em;
    }
}

.switching-billing-cycle,
.switching-button{
    display: flex;
    align-items: center;
    font-size: 0.9em;

    p{
        margin: 0;
    }
}

.switching-billing-cycle{
    border: 1px solid #dee2e6;
    width: 100%;
    margin: 0.7em 0 0 0;
    justify-content: space-between;
    padding: 0.7em;

    .switch-on,
    .switch-off{
        display: none;
    }

    .switch.btn{
        min-height: 1.2em;
    }

    .switch.btn{
        min-width: 3em;
    }

    .switch-handle{
        height: 1.8em;
    }
    
    .switching-button{
        p{
            margin: 0 0.5em 0 0;
        }
    }
}