.title-setup-management{
    display: flex;
    align-items: center;
    padding: 0 0 1em 0;
    border-bottom: 1px solid #E9ECEF;

    h5{
        margin: 0;
        font-weight: bold;
    }
}

.aws-creds-management-container{
    width: 95%;

    .table thead tr{
        border-bottom: none;
    }

    .table td, .table th{
        padding: 0
    }

    .table{
        width: 100%;
    }

    .error-display{
        font-size: 0.9em;
        font-weight: bold;
        width: 95%;
        margin: 1em 1em 1.5em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p{
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
}

.selected-aws-cred{
    border-bottom: 1px solid #E9ECEF;

    p{
        margin: 2em 0 0.5em 0;
        font-size: 1.1em;
        font-weight: bold;
    }
}

.inputs-new-aws-credential{
    display: flex;
    flex-wrap: wrap;

    input, select{
        width: 47%;
        margin: 0 0.5em 0.5em 0;
    }
}

.new-aws-credential{
    padding: 1em 0 1em 0;
    border-bottom: 1px solid #E9ECEF;

    p{
        font-size: 1.1em;
        font-weight: bold;    
    }
    
    display: flex;
    flex-direction: column;
}

.newCredBtn-1{
    margin: 0.5em 0 0 0;
    font-weight: bold;
}

.launchTemplates{
    font-weight: bold;
    background-color: #ff6701;   
    border: 1px solid #ff6701;
    margin: 1em 0 1em 0;
}

.button-container-aws{
    width: 95%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; 
}

.button-container-launch{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dom-reg-question{
    display: flex;
    align-items: center;
    p{
        font-size: 0.9em !important;
        margin: 0;
        font-weight: bold;
    }
}

.management-creds-container{
    display: flex;
    margin: 2em 0 0 0;

    p{
        font-size: 1.1em;
        font-weight: bold;  
        width: 30%;   
    }

    .options-aws{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button{
            width: 20%;
            margin: 0 0.5em 0 0;
        }
    }
}

.list-aws-creds{
    .table{
        margin: 1em 0 0 0;
        border: 1px solid #E9ECEF;
    }


    .table thead tr{
        border-bottom: 1px solid;
        color: white;
        background-color:  #909aff;
    }

    .table thead th{
        padding: 0.2em;
    }

    .table td{
        padding: 0.5em 0 0 0.5em;
    }
}

.check-radio{
    input{
        box-shadow: none;
        width: 1em;
        height: 1em;
    }
}

.edit-cred{
    display: none;
}

.edit-cred-show{
    display: flex;
}