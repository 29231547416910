.user-subscription-container{
    padding: 2em;
}

.user-subscription-tittle{
    font-family: BasisGrotesquePro, Arial, sans-serif;
    font-size: 1.125em;
    font-weight:bold;
}

.card-user-subscription{
    display: flex;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 1em;
    align-items: center;
    width: 100%;
    font-weight: bold;
    cursor: pointer;

    .avatar-user{
        width: 20%;
    }

    .name-user{
        width: 70%;
    }

    .type-sub{
        width: 20%;
    }

    .avatarPicTop{
        margin: 0;
    }

    p{
        margin:0;
        text-transform: capitalize;
    }


}

.card-user-subscription-active {
    display: flex;
    border: 1px solid #1d7aff;
    border-radius: 5px;
    padding: 1em;
    align-items: center;
    width: 100%;
    font-weight: bold;
    cursor: pointer;

    .avatar-user{
        width: 20%;
    }

    .name-user{
        width: 70%;
    }

    .type-sub{
        width: 20%;
    }

    .avatarPicTop{
        margin: 0;
    }

    p{
        margin:0;
        text-transform: capitalize;
    }
}

.display-none{
	display:none;
}

.display-modal{
	display:flex;
}