* {
  margin: 0;
  padding: 0;
  }
  
  .background{
    position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  } 
  
  .error-message{
    font-family: BasisGrotesquePro, Arial, sans-serif;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 40%;
    transform: translateY(-50%);
  }
  
#error-title1{
    font-size: 3em;
    font-weight: 900;
    color:#343a40e0;
  }
  
 #error-title2{
    font-size: 7em;
    font-weight: 900;
    color:#1e7aff;
  }

  #error-text{
    width: 100%;
    font-weight: 500;
    color:#343a40e0;
    margin-bottom: 1.5em;
  }
  
 #notFoundIcon{
    color: #102589;
    width: 3em;
    height: 3em;
    margin-right: 1em;
    margin-bottom: -1.5em;
    margin-left: -4em;
  }

  .goback-btn{
    width: 10em;
    font-weight: bold;
    border: none;
    border-radius: 0.250em;
    color: #ffff;
    background-color:#1e7aff;
    text-align: center;
  }
  
  .goback-btn:hover{
    border: none;
    background-color:  #005b8f;
  }

  .icon-auth-text{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    p{
      margin: 0;
    }

    svg{
      color: #102589;
      font-size: 2em;
      margin: 0 0.8em 0 0;
    }
  }