.next-button-templates{
    display: flex;
    width: 100%;
    padding: 0 4em;
    justify-content: flex-end;

    .btn-primary.disabled, .btn-primary:disabled{
        border: 1px solid #FF6602;
        background-color: transparent;
        color: #FF6602;
    }
}

.next-button-templates-2{
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .btn-primary.disabled, .btn-primary:disabled{
        border: 1px solid #FF6602;
        background-color: transparent;
        color: #FF6602;
    }
}

.input-subdomain-container{
    display: flex;
    align-items: center;
    width: 100%;

    input{
        width: 100%;
    }
}

.input-title-templates{
    width: 100%;
}

.input-title-input{
    width: 100%;
}

.button-launch-template{
    .btn-primary.disabled, .btn-primary:disabled{
        background-color: #FF6602;
        border: 1px solid #FF6602;
    }
}